
import styles from './modalSuccess.module.scss';
import { useSelector } from 'react-redux';
import store from '../../../toolkitRedux';
import { reduserSlice } from '../../../toolkitRedux/toolkitReducerOffers';

const ModalSuccess = () => {
  const { anonymously } = useSelector((state) => state.offers)
  const dispatch = store.dispatch;
  const { getModalsuccess, getModalConsent } = reduserSlice.actions;
  function close() {
    dispatch(getModalsuccess(false));
    dispatch(getModalConsent(false));
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  }
  return (
    <div className={styles.modal__container}>
      <button className={styles.close} onClick={close}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L1 13M1 1L13 13" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      {anonymously ? (
        <div className={styles.container__anonymously}>
          <p className={styles.title1}>Благодарим за ваше предложение.<br/>
            Оно будет рассмотрено в ближайшее время</p>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="40" fill="#00FBF4"/>
            <path d="M24 37.1818L34.9851 51L56 27" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className={styles.title2}>Ваше предложение отправлено анонимно</p>
        </div>
          
      ) : (
          <div className={styles.container}>
            <p className={styles.title}>Благодарим за ваше предложение.<br/>
              В ближайшее время оно будет рассмотрено.<br/>
              В случае успешного рассмотрения вам  будет назначено достижение «Идейный».</p>
            <img src="./Идейный.png" alt="Идейный" />
          </div>
      )}
      
    </div>
  );
};
export default ModalSuccess;