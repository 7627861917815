import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Menu } from '../Menu';

import hamburger from '../../assets/hamburger.svg';
import close from '../../assets/close.svg';
import logo from '../../assets/logo.svg';

import { employeeData, PROFILE_INFO } from '../../mockData/Data';

import styles from './header.module.scss';
import { Medal } from '../Medal';

export const Header = () => {
  const navigate = useNavigate();

  const location = useLocation();
  // const location = useLocation().pathname.split('/')[1];

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsOpen(true);
    document.body.classList.add('block');
    document.body.style.touchAction = 'none';
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handleGoHome = () => {
    navigate('/main');
  };

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  return (
    <>
      <header className={styles.header}>
        {isOpen ? (
          <div className={styles.close}>
            <img src={close} alt="close" onClick={handleCloseMenu} />
          </div>
        ) : (
          <img
            src={hamburger}
            alt="hamburger"
            onClick={handleOpenMenu}
            className={styles.hamburger}
          />
        )}
        {/* <NavLink to={'/main'}> */}
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
          onClick={handleGoHome}
        />
        {/* </NavLink> */}
        {location.pathname !== '/profile' ? (
          // <NavLink to={'/profile'}>
          <div className={styles.user}>
            <img
              src={PROFILE_INFO.userPhoto[0]}
              alt="user"
              onClick={handleGoToProfile}
              className={styles.userPhoto}
            />
            <div className={styles.menuIcon}>
              <Medal
                countAwards={employeeData.achievement_bonuses_sum}
                width={50}
                size={12}
                background={'#2D2D2E'}
              />
            </div>
          </div>
        ) : // </NavLink>
        null}
      </header>

      {isOpen && <Menu setIsOpen={setIsOpen} />}
    </>
  );
};
