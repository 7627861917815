import styles from './offers.module.scss';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import File from './components/File';
import store from '../../toolkitRedux';
import { reduserSlice } from '../../toolkitRedux/toolkitReducerOffers';
import { useSelector } from 'react-redux';
import './swiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css';
import { FreeMode } from 'swiper';
import ModalConsent from './components/modalConsent';

const Offers_page = () => {
  const [errorsFile, getErrorsFile] = useState(false);
  const [dataFile, getDataFile] = useState([]);
  const dispatch = store.dispatch;
  const {
    addCardForm,
    getModalConsent,
    getAnonymously,
    getdataText,
    getLengthSymbols,
  } = reduserSlice.actions;
  const { file, modalconsent, dataText, lengthSymbols } = useSelector(
    (state) => state.offers
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });
  function Change(event) {
    dispatch(getLengthSymbols(event.target.value.length));
    dispatch(getdataText(event.target.value));
  }
  function setImage(image) {
    const imageFile = URL.createObjectURL(image);

    const result = { type: image.type, url: imageFile };
    if (image.size > 200000000) {
      getErrorsFile(true);
    } else {
      dispatch(addCardForm(result));
      getErrorsFile(false);
    }
  }
  const onSubmit = (data) => {
    getDataFile(file);

    dispatch(getModalConsent(true));
    dispatch(getdataText(data.text));
  };
  if (modalconsent) {
    document.body.classList.add('block');
    document.body.style.touchAction = 'none';
  }
  return (
    <div className={styles.offers__container}>
      <div className={styles.offers__title__container}>
        <h2 className={styles.offers__title}>Предложения</h2>
        <button className={styles.offers__goBack}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 1L1 17M1 1L17 17"
              stroke="#EBEBEB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.offers__info__container}>
        <p className={styles.offers__info}>
          Используя данную форму вы можете отправить свое предложение
          непосредственно руководству сети (франшизы):
        </p>
        <p className={styles.offers__info__title}>Текст обращения *</p>
      </div>
      <form
        className={styles.form__container}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.form__list__text}>
          <label>
            <textarea
              onInput={(event) => Change(event)}
              value={dataText}
              maxLength="1000"
              className={styles.form__textarea}
              {...register('text', {
                required: 'Поле обязательное для заполнения',
              })}
            />
          </label>
          <span className={styles.form__span}>{lengthSymbols}/1000</span>
          {errors?.text && (
            <p className={styles.error__span}>{errors.text.message}</p>
          )}
        </div>
        <div className={styles.form__list__file + ' file'}>
          {file.length == 5 ? null : (
            <label className={styles.form__list}>
              <input
                className={styles.input__file}
                type="file"
                accept="image/*,video/*"
                {...register('file', {
                  onChange: (e) => setImage(e.target.files[0]),
                })}
              />
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="79"
                  height="79"
                  rx="9.5"
                  stroke="#D9D9D9"
                />
                <path
                  d="M42.5 32H37.5L35 35H32C31.4696 35 30.9609 35.2107 30.5858 35.5858C30.2107 35.9609 30 36.4696 30 37V46C30 46.5304 30.2107 47.0391 30.5858 47.4142C30.9609 47.7893 31.4696 48 32 48H48C48.5304 48 49.0391 47.7893 49.4142 47.4142C49.7893 47.0391 50 46.5304 50 46V37C50 36.4696 49.7893 35.9609 49.4142 35.5858C49.0391 35.2107 48.5304 35 48 35H45L42.5 32Z"
                  stroke="#BDBDBD"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M40 44C41.6569 44 43 42.6569 43 41C43 39.3431 41.6569 38 40 38C38.3431 38 37 39.3431 37 41C37 42.6569 38.3431 44 40 44Z"
                  stroke="#BDBDBD"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {file.length != 0 ? null : (
                <p className={styles.addFile__p}>Добавить фото/видео</p>
              )}
            </label>
          )}
          {errorsFile && (
            <p className={styles.error__span__file}>
              Размер файла превысил максимально допустимый размер (200 МБ)
            </p>
          )}
          {file.length != 0 ? (
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              freeMode={true}
              modules={[FreeMode]}
              className="mySwiper"
            >
              {file.map((card, index) => (
                <SwiperSlide key={index}>
                  <File card={card} index={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </div>
        <div className={styles.form__button}>
          <button
            className={styles.submit}
            type="submit"
            disabled={Object.keys(errors).length}
            onClick={() => dispatch(getAnonymously(false))}
          >
            Отправить
          </button>
          <button
            onClick={() => dispatch(getAnonymously(true))}
            className={styles.submit__anonymous}
            type="submit"
            disabled={Object.keys(errors).length}
          >
            Отправить анонимно
          </button>
        </div>
      </form>
      {modalconsent && <ModalConsent reset={reset} file={dataFile} />}
    </div>
  );
};
export default Offers_page;
