import moment from 'moment';

const getMonthPeriod = () => {
  const today = moment();
  const startMonth = today.clone().startOf('month').format('X');
  const endMonth = today.clone().endOf('month').format('X');

  return { start_date: startMonth, end_date: endMonth };
};

const getWeekPeriod = () => {
  const today = moment();
  const startWeek = today.clone().startOf('isoWeek').format('X');
  const endWeek = today.clone().endOf('isoWeek').format('X');

  return { start_date: startWeek, end_date: endWeek };
};

const getDayPeriod = () => {
  const today = moment();
  const startDay = today.clone().startOf('day').format('X');
  const endDay = today.clone().endOf('day').format('X');

  return { start_date: startDay, end_date: endDay };
};

export const getStartAndEndData = (value) => {
  switch (value) {
    case 'За месяц':
      return getMonthPeriod();
    case 'За неделю':
      return getWeekPeriod();
    case 'За день':
      return getDayPeriod();
  }
};
