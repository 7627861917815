import styles from './modalConsent.module.scss';
import store from '../../../toolkitRedux';
import { reduserSlice } from '../../../toolkitRedux/toolkitReducerOffers';
import { useSelector } from 'react-redux';
import ModalSuccess from './modalSuccess';

const ModalConsent = ({ reset, file }) => {
  const dispatch = store.dispatch;
  const {
    getModalConsent,
    getModalsuccess,
    resetCard,
    getdataText,
    getLengthSymbols,
  } = reduserSlice.actions;
  const { Modalsuccess, dataText, anonymously } = useSelector(
    (state) => state.offers
  );
  const { token, userId } = useSelector((state) => state.auth);

  function close() {
    dispatch(getModalConsent(false));
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  }
  async function goSubmit() {
    const formData = new FormData();
    formData.append('text', dataText);
    for (let i = 0; i < file.length; i++) {
      const response = await fetch(file[i].url);
      const blob = await response.blob();
      formData.append('uploaded_files', blob, `${i}.jpeg`);
    }

    if (!anonymously) {
      formData.append('user', userId);
    }

    await fetch('https://dev.fcm.by/hr/offer/', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    reset();
    dispatch(resetCard());
    dispatch(getdataText(''));
    dispatch(getLengthSymbols(0));
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
    dispatch(getModalsuccess(true));
  }

  return (
    <div className={styles.modal__container}>
      <div className={styles.modal}>
        <button className={styles.close} onClick={close}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#EBEBEB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        {anonymously ? (
          <p className={styles.title}>
            Подтвердить отправление предложения
            <br /> (без указания личных данных) ?
          </p>
        ) : (
          <p className={styles.title}>Подтвердить отправление предложения?</p>
        )}
        <div className={styles.submit}>
          <button className={styles.button} onClick={goSubmit}>
            Отправить
          </button>
          <button className={styles.butto__back} onClick={close}>
            Отмена
          </button>
        </div>
      </div>
      {Modalsuccess && <ModalSuccess />}
    </div>
  );
};
export default ModalConsent;
