import styles from './Modal.module.scss';

export const Modal = ({
  activeModal,
  setActive,
  children,
  width = '88%',
  bg = '#2d2d2e',
}) => {
  const { modal, active, modal__content } = styles;

  return (
    <div
      className={activeModal ? [modal, active].join(' ') : [modal]}
      onClick={() => setActive(false)}
      onTouchMove={(e) => e.preventDefault()}
    >
      <div
        className={
          activeModal ? [modal__content, active].join(' ') : modal__content
        }
        style={{ width: width, background: bg }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};
