import styles from'./buttonPeriod.module.scss';
import { useState } from "react";
import store from "../../../toolkitRedux/index"
import { reduserSlice } from '../../../toolkitRedux/taringReducer';

const ButtonPeriod = () => {
  const dataOptions = ['За период' ,'За месяц', 'За неделю', 'За день'];
  const [value, setValue] = useState('За период');
  const dispatch = store.dispatch;
  const { addOption } = reduserSlice.actions;
  
  const [details, setDetails] = useState(false)
  const handleChoseOption = (event) => {
    setValue(event.target.dataset.period);
    dispatch(addOption(event.target.dataset.period));
  };
  function Details() {
    if (!details) {
      setDetails(true)
    } else {
      setDetails(false)
    }
  }
  return (
    <>
      <button className={styles.sort__container} onClick={Details}>
        {value}
        {details ? (
          <svg className={styles["fine__button-svg"]} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L7 1L13 7" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          ) : (
          <svg className={styles["fine__button-svg"]} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )}
        {details && (
          <div className={styles.details__container}>
            {dataOptions.map((option) => {
              if (option !== value) {
                return (
                  <li
                    key={option}
                    data-period={option}
                    className={styles.select__option}
                    onClick={(event) => handleChoseOption(event)}
                  >
                    {option}
                  </li>
                );
              }
            })}
          </div>
        )}
      </button>
    </>
    
  );
};
export default ButtonPeriod;