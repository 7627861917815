import styles from './Medal.module.scss';

export const Medal = ({
  countAwards,
  width,
  size,
  background,
  fontWeight,
  imgSize = 24,
}) => {
  const { medal, medal__img } = styles;

  return (
    <div
      className={medal}
      style={{
        width: width,
        fontSize: size,
        background: background,
        fontWeight: fontWeight,
      }}
    >
      <div
        style={{
          width: imgSize,
          height: imgSize,
        }}
        className={medal__img}
      ></div>
      <p>{countAwards}</p>
    </div>
  );
};
