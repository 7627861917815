import { useNavigate } from 'react-router';

import styles from './Possibility.module.scss';
import BaristaCourse from './assets/coffee.png';
import Backpack from './assets/backpack.png';
import PhoneCase from './assets/phoneCase.png';
import { Award } from '../../components/Award';
import { useEffect, useState } from 'react';
import store from '../../toolkitRedux';
import { fetchPossibilityData } from '../../toolkitRedux/PossibilityReducer';
import { useSelector } from 'react-redux';
import { getTime } from './components/helpers';

export const opportunityData = [
  {
    id: 1,
    imageSrc: BaristaCourse,
    title: 'Урок по латте-арт',
    description: 'Основы',
    awards: 25,
    money: 295,
  },
  {
    id: 2,
    imageSrc: Backpack,
    title: 'Рюкзак',
    description: 'С логотипом Hotfix',
    awards: 94,
    money: 150,
  },
  {
    id: 3,
    imageSrc: PhoneCase,
    title: 'Чехол на iPhone',
    description: 'С логотипом Hotfix',
    awards: 21,
    money: 60,
  },
];

const statuses = {
  processed: 'Размещен',
  confirmed: 'Одобрен',
  delivered: 'Получен',
  denied: 'Отклонен',
};

const orders = [
  {
    id: 1,
    orderNumber: 205,
    good: [...opportunityData.filter((item) => item.id === 1)],
    date: '02.05.2023',
    paymentMethod: 'bonus',
    status: statuses.processed,
  },
  {
    id: 2,
    orderNumber: 170,
    good: [...opportunityData.filter((item) => item.id === 2)],
    date: '03.01.2023',
    paymentMethod: 'cash',
    status: statuses.delivered,
  },
  {
    id: 3,
    orderNumber: 586,
    good: [...opportunityData.filter((item) => item.id === 3)],
    date: '28.12.2022',
    paymentMethod: 'bonus',
    status: statuses.denied,
  },
  {
    id: 4,
    orderNumber: 580,
    good: opportunityData.filter((item) => item.id === 2),
    date: '28.12.2022',
    paymentMethod: 'cash',
    status: statuses.delivered,
  },
  {
    id: 5,
    orderNumber: 579,
    good: opportunityData.filter((item) => item.id === 2),
    date: '28.12.2022',
    paymentMethod: 'cash',
    status: statuses.denied,
  },
];

export const PossibilityPage = () => {
  const {
    possibility,
    possibility__container,
    possibility__title,
    possibility__list,
    possibility__item,
    possibility__image,
    possibility__name,
    possibility__awards,
    possibility__description,
    possibility__buy,
    possibility__wrapper,
    possibility__btns,
    possibility__history,
    possibility__order,
    possibility__btn_active,
    history__wrapper,
    history__info,
    history__title,
    history__payment,
    history__status,
    history__img,
  } = styles;

  const navigate = useNavigate();

  const openPossibilityItem = (event) => {
    const id = event.currentTarget.dataset.id;
    navigate(`/possibilities/:${id}`);
    localStorage.setItem('activeBtn', isActiveBtn);
  };
  const localData = localStorage.getItem('activeBtn');

  const [isActiveBtn, setIsActiveBtn] = useState(
    localData ? localData : 'order'
  );

  const changeActiveBtn = async (event) => {
    if (event.target.dataset.name !== isActiveBtn) {
      setIsActiveBtn(event.target.dataset.name);
    }
  };

  const dispatch = store.dispatch;
  const { possibilities } = useSelector((state) => state.possibility);

  useEffect(() => {
    dispatch(fetchPossibilityData());

    getTime('Wed Apr 11 2023 15:39:00 GMT+0300');
  }, [dispatch]);

  return (
    <main className="main">
      <section className={possibility}>
        <div className={['container', possibility__container].join(' ')}>
          <div className={['title', possibility__title].join(' ')}>
            Возможности
          </div>
          <div className={possibility__btns}>
            <button
              data-name="order"
              className={
                isActiveBtn === 'order'
                  ? [possibility__order, possibility__btn_active].join(' ')
                  : possibility__order
              }
              onClick={(event) => {
                changeActiveBtn(event);
              }}
            >
              Заказать
            </button>
            <button
              data-name="history"
              className={
                isActiveBtn === 'history'
                  ? [possibility__history, possibility__btn_active].join(' ')
                  : possibility__history
              }
              onClick={(event) => {
                changeActiveBtn(event);
              }}
            >
              Мои заказы
            </button>
          </div>
          {isActiveBtn === 'order' ? (
            <ul className={possibility__list}>
              {possibilities.map((item) => (
                <li
                  data-id={item.id}
                  className={possibility__item}
                  key={item.id}
                  onClick={(event) => openPossibilityItem(event)}
                >
                  <div
                    className={possibility__image}
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  ></div>
                  <div>
                    <p className={possibility__name}>{item.title}</p>
                    <p className={possibility__description}>
                      {item.description}
                    </p>
                    <div className={possibility__wrapper}>
                      <div className={possibility__awards}>
                        <Award
                          countAwards={item.value_in_points}
                          width={57}
                          size={14}
                          imgSize={26}
                        />
                      </div>
                      <div className={possibility__buy}>Купить</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <ul className={possibility__list}>
              {orders.map((order) => (
                <li key={order.id} className={history__wrapper}>
                  <div className={history__img}>
                    <img src={order.good[0].imageSrc} alt="Good image" />
                  </div>

                  <div className={history__info}>
                    <div>
                      Заказ № {order.orderNumber} от {order.date}
                    </div>
                    {order.id === 1 ? (
                      <div
                        className={history__title}
                        data-id={order.good[0].id}
                        onClick={(event) => openPossibilityItem(event)}
                      >
                        {order.good[0].title} основы
                      </div>
                    ) : (
                      <div
                        className={history__title}
                        data-id={order.good[0].id}
                        onClick={(event) => openPossibilityItem(event)}
                      >
                        {order.good[0].title}
                      </div>
                    )}

                    {order.paymentMethod === 'bonus' ? (
                      <div className={history__payment}>
                        <div>
                          <p>Оплата</p>
                          <p>Бонусными баллами:</p>
                        </div>

                        <Award
                          countAwards={order.good[0].awards}
                          width={50}
                          size={14}
                          background={'#2D2D2E'}
                        />
                      </div>
                    ) : (
                      <div className={history__payment}>
                        <div>
                          <p>Оплата</p>
                          <p>Самостоятельная оплата:</p>
                        </div>
                        <div>{order.good[0].money} Br</div>
                      </div>
                    )}
                    <div className={history__status}>{order.status}</div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </main>
  );
};
