import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../toolkitRedux';
import styles from './notifications.module.scss';
import { fetchData } from "../../toolkitRedux/notificationsReducer";
import List from './list';
import { useState } from 'react';

const Notifications = () => {
  const dispatch = store.dispatch;
  const { data } = useSelector((state) => state.notifications);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(0);
    if (Object.keys(data).length !== 0) {
      data.map((el) => {
        if (el.viewed === false) {
          setQuantity((x) => x + 1)
        }
      })
    }
  }, [data])
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch])
  if (Object.keys(data).length == 0) {
    return (
      <div>
        <h2 >Ничего не найдено </h2>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.container__title}>
        <h2 className={styles.title}>Уведомления</h2>
        <div className={styles.quantity__container}>
          <p className={styles.quantity}>{quantity}</p>
        </div>
      </div>
      <div className={styles.notifications__container}>
        {data.map((el) => (
          <List el={el} key={el.id} />
          ))}
      </div>
    </div>
  );
};
export default Notifications;