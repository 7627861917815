import { VscClose } from 'react-icons/vsc';
import { AchieveImageBig } from '../../../components/AchieveImage/big';
import { Award } from '../../../components/Award';
import styles from '../../AchievementsPage/components/ModalContent.module.scss';

export const ModalContentSlide = ({ data, setActive }) => {
  const {
    content,
    content__title,
    content__name,
    content__close,
    content__icon,
    content__description,
    content__total,
    content__image,
    content__subtitle,
    content__cup,
    content__wrapper,
    content__text,
  } = styles;

  return (
    <div className={content}>
      <div className={content__title}>
        <h2 className={content__name}>{data.title}</h2>
        <button className={content__close} onClick={setActive}>
          <VscClose className={content__icon} size={20} />
        </button>
      </div>
      <div className={content__description}>
        <div className={content__total}>
          <div className={content__image}>
            <AchieveImageBig
              progress="100"
              imgActive={data.imgActive}
              imgDisable={data.img}
            />
          </div>
          <div className={content__subtitle}>Награда при получении:</div>
          <div className={content__cup}>
            <Award
              countAwards={data.award}
              width={51}
              size={12}
              background={
                'linear-gradient(180deg,rgba(255, 255, 255, 0.14) 0%,rgba(255, 255, 255, 0.2175) 100%)'
              }
              fontWeight={500}
            />
          </div>
        </div>
        <div className={content__wrapper}>
          <div className={content__text}>{data.content}</div>
        </div>
      </div>
    </div>
  );
};
