import styles from './violation.module.scss';

const Violation = ({ fine }) => {
  return (
    <>
      <div className={styles.violations__list}>
        <p className={styles.violations__title}>{fine.title}</p>
        <p className={styles.violations__sum}>{fine.fine_amount}</p>
      </div>
    </>
  );
};
export default Violation;
