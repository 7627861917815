import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  details: false,
  file: JSON.parse(localStorage.getItem('card')) || [],
  modalconsent: false,
  anonymously: false,
  Modalsuccess: false,
  dataText: localStorage.getItem('notes') || '',
  lengthSymbols: localStorage.getItem('length') || 0,
};

export const reduserSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    addDetails(state) {
      state.details = true;
    },
    removeDetails(state) {
      state.details = false;
    },
    addCardForm(state, action) {
      localStorage.setItem(
        'card',
        JSON.stringify([...state.file, action.payload])
      );
      state.file = [...state.file, action.payload];
    },
    resetCard(state) {
      localStorage.setItem('card', JSON.stringify([]));
      state.file = [];
    },
    deleteFile(state, action) {
      state.file = [...state.file];
      state.file.splice(action.payload, 1);
      localStorage.setItem('card', JSON.stringify(state.file));
    },
    getModalConsent(state, action) {
      state.modalconsent = action.payload;
    },
    getAnonymously(state, action) {
      state.anonymously = action.payload;
    },
    getModalsuccess(state, action) {
      state.Modalsuccess = action.payload;
    },
    getdataText(state, action) {
      localStorage.setItem('notes', action.payload);
      state.dataText = action.payload;
    },
    getLengthSymbols(state, action) {
      localStorage.setItem('length', action.payload);
      state.lengthSymbols = action.payload;
    },
  },
});
export default reduserSlice.reducer;
