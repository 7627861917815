import styles from'./timesheetPage.module.scss';
import Calendar from './components/calendar';
import moment from 'moment';
import Monitor from './components/monitor';
import { useState } from 'react';
import { useEffect } from 'react';
import store from '../../toolkitRedux/index'
import { fetchData } from "../../toolkitRedux/calendarReducer";
import { useSelector } from 'react-redux'
import Description from './components/description';
import { reduserSlice } from '../../toolkitRedux/calendarReducer';

const TimesheetPage = () => {
  const dispatch = store.dispatch;
  const { data } = useSelector((state) => state.calendar);
  const { setEvents, setMonth } = reduserSlice.actions;

  const [today, setToday] = useState(moment());

  const startMonth = today.clone().startOf('month').startOf('week'); 
  const endMonth = today.clone().endOf('month');
  const endMonthUnix = endMonth.clone().format('X');
  
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch])


  
  useEffect(() => {
    if (Object.keys(data).length != 0) {
      const dataNew = data.filter(date => date.time >= today.clone().startOf('month').startOf('week').clone().format('X') && date.time <= endMonthUnix);
      dispatch(setEvents(dataNew));
      const dataMonth = data.filter(date => date.time >= today.clone().startOf('month').clone().format('X') && date.time <= endMonthUnix);
      dispatch(setMonth(dataMonth));
    }
  }, [data, dispatch, endMonthUnix, setEvents, setMonth, today])
  
  moment.updateLocale('ru', { week: { dow: 1 } })
  
  
  const prev = () => {
    setToday(prev => prev.clone().subtract(1, 'month'));
  }
  const next = () => {
    setToday(prev => prev.clone().add(1, 'month'));
  }
  
  const calendar = [];
  const day = startMonth.clone();

  while (!day.isAfter(endMonth)) {
    calendar.push(day.clone());
    day.add(1, 'day');
  }

  if (Object.keys(data).length == 0) {
    return (
      <div>
        <h2 >Ничего не найдено </h2>
      </div>
    );
  }
  
  return (
    <div className={styles.timesheet__container}>
      <h2 className={styles.timesheet__title}>Табель рабочего времени</h2>
      <Monitor today={today} next={next} prev={prev} />
      <Calendar calendar={calendar} today={today} />
      <Description />
    </div>
  );
};
export default TimesheetPage;