import { PROFILE_INFO } from '../../mockData/Data';

export const PROFILE_VALUES = [
  {
    title: 'Имя',
    value: PROFILE_INFO.name
  },
  {
    title: 'Отчество',
    value: PROFILE_INFO.surname
  },
  {
    title: 'Фамилия',
    value: PROFILE_INFO.lastname
  },
  {
    title: 'Пол',
    value: PROFILE_INFO.sex
  },
  {
    title: 'День рождения',
    value: PROFILE_INFO.birthDate
  },
  {
    title: 'Телефон',
    value: PROFILE_INFO.tel
  },
  {
    title: 'Email',
    value: PROFILE_INFO.email
  }
];
