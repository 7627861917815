import styles from './dayItem.module.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DayItem = ({ dayItem, today }) => {
  
  const navigate = useNavigate();
  const { events, activeButton } = useSelector((state) => state.calendar);
  const currentDay = moment().isSame(dayItem, 'day');
  const currentMonth = today.isSame(dayItem, 'month');
  const start = dayItem.format("X");
  const end = dayItem.clone().endOf('day').format("X");
  const result = events.filter(event => event.time >= start && event.time <= end);
  
  return (
    <div className={currentDay ? styles.calendar__cellCurrent : styles.calendar__cell} onClick={() => navigate(`/timesheet/${dayItem.format('X')}`)}>
      <p className={currentMonth ? styles.date__pCurrentEmpty : styles.date__p}>{dayItem.format('D')}</p>
      {result.map((el) => (
        (
          el.plan ? (
            <div  key={el}>
              <p className={currentMonth ? styles.date__pCurrent : styles.date__p}>{dayItem.format('D')}</p>
              <svg className={currentMonth ? styles.future__work : styles.hour__worked} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H8V8H4C1.79086 8 0 6.20914 0 4V0Z" />
              </svg>
            </div>
          ) : (
            <div key={el}>
              <p className={currentMonth ? styles.date__pCurrent : styles.date__p}>{dayItem.format('D')}</p>
              <svg className={currentMonth ? styles.hour__workedCurrent : styles.hour__worked} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H8V8H4C1.79086 8 0 6.20914 0 4V0Z" />
              </svg>
            </div> 
          )
        )
      ))}
      <div className="">
        {result.map((el) => (
          <div key={el} className={styles.info__container}>
            {el.plan ? (<p className={currentMonth ? styles.hoursWorkedCurrent : styles.hoursWorked}>{el.plan}</p>) : (
              <>
                <p className={currentMonth ? styles.hoursWorkedCurrent : styles.hoursWorked}>{el.hoursWorked}</p>
                {(activeButton == "Все" && el.revenue) && <p className={currentMonth ? styles.revenueCurrent : styles.revenue}>{el.revenue.toFixed(0)}</p>}
                {(activeButton == "Зарплата" && el.salary) && <p className={currentMonth ? styles.salaryCurrent : styles.salary}>{el.salary.toFixed(0)}</p>}
                {(activeButton == "Все" && el.achievement) && <div className={currentMonth ? styles.achievementCurrent : styles.achievement}></div>}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default DayItem;