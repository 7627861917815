import { configureStore } from '@reduxjs/toolkit';

import FineReducer from './toolkitReducer';
import taringReducer from './taringReducer';
import calendarReducer from './calendarReducer';
import offersReducer from './toolkitReducerOffers';
import historyReducer from './toolkitReducerHistory';
import userReducer from './userReducer';
import mainSalaryReducer from './mainSalaryReducer';
import PossibilityReducer from './PossibilityReducer';
import notificationsReducer from './notificationsReducer';
import authReducer from './authReducer';

const store = configureStore({
  reducer: {
    fine: FineReducer,
    taring: taringReducer,
    offers: offersReducer,
    history: historyReducer,
    user: userReducer,
    calendar: calendarReducer,
    main: mainSalaryReducer,
    possibility: PossibilityReducer,
    notifications: notificationsReducer,
    auth: authReducer,
  },
});

export default store;
