import styles from './ProgressBarSvg.module.scss';

export const ProgressBarSvg = ({ progress }) => {
  const widthCircle = 90;
  const borderWidth = 2;
  const center = widthCircle / 2;
  const radius = center - borderWidth;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <div className={styles['progress-ach']}>
      <svg className={styles['progress-ach__svg']}>
        <circle
          className={styles['progress-ach__track']}
          cx={center}
          cy={center}
          r={radius}
        />
        <circle
          className={styles['progress-ach__indicator']}
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </svg>
    </div>
  );
};
