import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dataPeriod from "./dataRating.json";
import dataDay from "./dataRatingDay.json";
import dataWeek from "./dataRatingWeek.json";
import dataMonth from "./dataRatingMonth.json";
export const fetchDataPeriod = createAsyncThunk("rating/fetchDataPeriod", async () => {
  return dataPeriod;
});
export const fetchDataDay = createAsyncThunk("rating/fetchDataDay", async () => {
  return dataDay;
});
export const fetchDataWeek = createAsyncThunk("rating/fetchDataWeek", async () => {
  return dataWeek;
});
export const fetchDataMonth = createAsyncThunk("rating/fetchDataMonth", async () => {
  return dataMonth;
});
export const initialState = {
  optionData: "За период",
  data: {},
  modal: false,
  idModal: 0,
  averageRating: '3.5',
  dataPeriod: {}
};

export const reduserSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    addOption(state, option) {
      state.optionData = option.payload;
    },
    addModal(state) {
      state.modal = true;
    },
    addIdModal(state, action) {
      state.idModal = action.payload;
    },
    closeModal(state) {
      state.modal = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataPeriod.fulfilled, (state, action) => {
        state.data = action.payload;
        state.dataPeriod = action.payload
      })
      .addCase(fetchDataDay.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchDataWeek.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchDataMonth.fulfilled, (state, action) => {
        state.data = action.payload;
      })
  },
});
export default reduserSlice.reducer;
