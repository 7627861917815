import { useNavigate } from 'react-router-dom';

import styles from './modalExit.module.scss';
import store from '../../toolkitRedux';
import { changeStatusAuth } from '../../toolkitRedux/authReducer';

const ModalExit = ({ closeModal }) => {
  const dispatch = store.dispatch;
  const navigate = useNavigate();
  document.body.classList.add('block');
  document.body.style.touchAction = 'none';

  function logOut() {
    dispatch(changeStatusAuth(false));
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    navigate('/');
  }

  return (
    <div className={styles.modal__container}>
      <div className={styles.modal}>
        <button className={styles.button__close} onClick={closeModal}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#EBEBEB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <p className={styles.title}>
          Вы уверены,
          <br /> что хотите выйти?
        </p>
        <div className={styles.button__container}>
          <button className={styles.button__cancel} onClick={closeModal}>
            Отмена
          </button>
          <button className={styles.button__exit} onClick={logOut}>
            Выйти
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalExit;
