import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import data from "./dataNotifications.json";
export const initialState = {
  data: {}
};

export const fetchData = createAsyncThunk("notifications/fetchData", async () => {
  return data;
});
export const reduserSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addDetails(state) {
      state.details = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default reduserSlice.reducer;
