import styles from './File.module.scss';
import './block.scss'
import { reduserSlice } from '../../../toolkitRedux/toolkitReducerOffers';
import store from '../../../toolkitRedux';
const File = ({ card, index }) => {
  const { deleteFile } = reduserSlice.actions;
  const dispatch = store.dispatch;
  function deleteImg() {
    dispatch(deleteFile(index));
  }
  if (card.type.includes('image')) {
    return (
      <div className={styles.file__container}>
        <img className={styles.file__img} src={card.url} alt="img"></img>
        <svg onClick={deleteImg} className={styles.svg}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#EBEBEB"/>
          <path d="M6.55556 7.33333V17C6.55556 18.1046 7.45099 19 8.55556 19H15.4444C16.549 19 17.4444 18.1046 17.4444 17V7.33333M6.55556 7.33333H8.88889M6.55556 7.33333H5M17.4444 7.33333H19M17.4444 7.33333H15.1111M15.1111 7.33333H8.88889M15.1111 7.33333V7C15.1111 5.89543 14.2157 5 13.1111 5H10.8889C9.78432 5 8.88889 5.89543 8.88889 7V7.33333" stroke="#202021" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>  
    )
  }
  return (
    <div className={styles.file__container}>
      <video className={styles.file__img}>
        <source src={card.url}/>
      </video>
      <svg onClick={deleteImg} className={styles.svg}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#EBEBEB"/>
          <path d="M6.55556 7.33333V17C6.55556 18.1046 7.45099 19 8.55556 19H15.4444C16.549 19 17.4444 18.1046 17.4444 17V7.33333M6.55556 7.33333H8.88889M6.55556 7.33333H5M17.4444 7.33333H19M17.4444 7.33333H15.1111M15.1111 7.33333H8.88889M15.1111 7.33333V7C15.1111 5.89543 14.2157 5 13.1111 5H10.8889C9.78432 5 8.88889 5.89543 8.88889 7V7.33333" stroke="#202021" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>
  );
};
export default File;