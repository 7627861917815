import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './authorization.module.scss';
import GoogleAuth from './components/googleAuth';
import logo from './logo.png';
import { Loader } from '../../components/Loader';
import store from '../../toolkitRedux';
import { fetchAuthEmailData } from '../../toolkitRedux/authReducer';

const Authorization = () => {
  const dispatch = store.dispatch;
  const isLoaded = useSelector((state) => state.auth.isLoaded);
  const [showPassword, getShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    reset();
    const body = {
      email: data.email,
      password: data.password,
      user_type: 'employee',
    };

    dispatch(fetchAuthEmailData(body));
  };

  return (
    <div className={styles.authorization__container}>
      {isLoaded ? (
        <Loader />
      ) : (
        <>
          <img className={styles.logo} src={logo} alt="logo" />
          <h2 className={styles.authorization__title}>
            Авторизация пользователя
          </h2>
          <form
            className={styles.form__container}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.form__list + ' ' + styles.email}>
              <label className={styles.authorization__label}>
                <input
                  className={styles.label__info}
                  type="email"
                  placeholder=" "
                  {...register('email', {})}
                ></input>
                <span className={styles.label__span}>Email:</span>
                {isDirty && (
                  <p className={styles.email__clue}>
                    Введите e-mail, указанный при регистрации
                  </p>
                )}
              </label>
              {errors?.email && (
                <p className={styles.authorization__error}>
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className={styles.form__list + ' ' + styles.password}>
              <label className={styles.authorization__label}>
                <input
                  className={styles.label__info}
                  placeholder=" "
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {})}
                ></input>
                <span className={styles.label__span}>Пароль:</span>
                <div
                  className={styles.button__password}
                  onClick={
                    showPassword
                      ? () => getShowPassword(false)
                      : () => getShowPassword(true)
                  }
                >
                  {showPassword ? (
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8C1 8 4 1 11 1C18 1 21 8 21 8C21 8 18 15 11 15C4 15 1 8 1 8Z"
                        stroke="#EBEBEB"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z"
                        stroke="#EBEBEB"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.88 8.88C8.58525 9.15465 8.34884 9.48585 8.18488 9.85385C8.02091 10.2218 7.93274 10.6191 7.92563 11.0219C7.91853 11.4247 7.99262 11.8248 8.14351 12.1984C8.29439 12.5719 8.51897 12.9113 8.80385 13.1962C9.08872 13.481 9.42806 13.7056 9.80161 13.8565C10.1752 14.0074 10.5753 14.0815 10.9781 14.0744C11.3809 14.0673 11.7782 13.9791 12.1462 13.8151C12.5141 13.6512 12.8454 13.4147 13.12 13.12M9.73 4.08C10.1513 4.02751 10.5754 4.00079 11 4C18 4 21 11 21 11C20.5529 11.9571 19.9922 12.8569 19.33 13.68M5.61 5.61C3.62125 6.96462 2.02987 8.82526 1 11C1 11 4 18 11 18C12.9159 18.0051 14.7908 17.4451 16.39 16.39M1 1L21 21"
                        stroke="#EBEBEB"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </label>
              {errors?.password && (
                <p className={styles.authorization__error}>
                  {errors.password.message}
                </p>
              )}
            </div>
            <div className={styles.form__list + ' ' + styles.remember}>
              <label className={styles.authorization__label}>
                <input
                  className={styles['label__info-agree']}
                  type="checkbox"
                  {...register('remember')}
                ></input>
                <p className={styles.remember__p}>Запомнить</p>
              </label>
            </div>
            <div className={styles.form__button}>
              <button
                className={styles.submit}
                type="submit"
                disabled={Object.keys(errors).length !== 0 || !isDirty}
              >
                Войти
              </button>
            </div>
          </form>
          <GoogleAuth />
        </>
      )}
    </div>
  );
};

export default Authorization;
