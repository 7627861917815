import styles from'./rating.module.scss';
import ButtonPeriod from './components/buttonPeriod';
import Stars from './components/stars';
import { useSelector } from 'react-redux'
import store from '../../toolkitRedux';
import { useEffect } from "react";
import { fetchDataPeriod, fetchDataDay, fetchDataMonth, fetchDataWeek } from "../../toolkitRedux/taringReducer";
import Review from './components/review';

const Rating = () => {
  const { optionData, data, averageRating, dataPeriod } = useSelector((state) => state.taring);
  const dispatch = store.dispatch;
  useEffect(() => {
    if (optionData === "За период") {
      dispatch(fetchDataPeriod());
    }
    if (optionData === "За месяц") {
      dispatch(fetchDataMonth());
    }
    if (optionData === "За неделю") {
      dispatch(fetchDataWeek());
    }
    if (optionData === "За день") {
      dispatch(fetchDataDay());
    }
  }, [dispatch, optionData]);
  if (Object.keys(data).length == 0) {
    return (
      <div>
        <h2 >Ничего не найдено </h2>
      </div>
    );
  }
  return (
    <div className={styles.rating__page}>
      <div className={styles.rating__container}>
        <h2 className={styles.rating__h2}>Рейтинг</h2>
        <ButtonPeriod/>
      </div>
      <div className={styles.reviews__container}>
        <div className={optionData !== "За период" ? (styles.reviews__amount__container + " " + styles.black) : styles.reviews__amount__container}>
          <p>Отзывы {averageRating}</p>
          <Stars number={averageRating}/>
          <p>({dataPeriod.length})</p>
        </div>
      </div>
      <div className={styles.scroll}>
        <div className={styles.reviewsAll__container}>
          {data.map((review) => (
            <Review review={review} key={review.id} />
          ))}
        </div>
      </div>
      
    </div>
  );
};
export default Rating;