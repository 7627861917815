import styles from'./description.module.scss';
import store from '../../../toolkitRedux/index';
import { useSelector } from 'react-redux';
import { reduserSlice } from '../../../toolkitRedux/calendarReducer';

const Description = () => {
  const dispatch = store.dispatch;
  const { workedOut, plan, revenue, salary, achievement, activeButton, salaryDetails } = useSelector((state) => state.calendar);
  const { setSalaryDetails } = reduserSlice.actions;
  
  return (
    <div className={styles.description__container}>
      <div className={styles.description__info__container}>
        <div className={styles.description__info__list}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="5" stroke="#00FBF4" strokeWidth="2"/>
          </svg>
          <p className={styles.description__info__p}>Отработано часов</p>
        </div>
        <div className={styles.description__info__list}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="5" stroke="#978AFA" strokeWidth="2"/>
          </svg>
          <p className={styles.description__info__p}>Плановая смена</p>
        </div>
        {activeButton == "Все" && (
          <div className={styles.description__info__list}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6" r="5" stroke="#F2994A" strokeWidth="2"/>
            </svg>
            <p className={styles.description__info__p}>Получено достижение</p>
          </div>
        )}
      </div>
      <div className={styles.list__container}>
        <div className={styles.list}>
          <h3 className={styles.list__h3}>Отработано за рабочие смены</h3>
          <p className={styles.list__blue}>{workedOut} часов</p>
        </div>
        <div className={styles.list}>
          <h3 className={styles.list__h3}>План работ до конца месяца</h3>
          <p className={styles.list__violet}>{plan} часов</p>
        </div>
        {activeButton == "Все" && (
          <>
            <div className={styles.list}>
              <h3 className={styles.list__h3}>Получено достижений</h3>
              <p className={styles.list__orange}>{achievement}</p>
            </div>
            <div className={styles.list}>
              <h3 className={styles.list__h3}>Выручка за отработанные смены</h3>
              <p className={styles.list__p}>{revenue.toFixed(2)} Br</p>
            </div>
          </>
        )}
        { (activeButton == "Все" || activeButton == "Зарплата") && (
          
            <div className={styles.list} onClick={salaryDetails ? () => dispatch(setSalaryDetails(false)) : () => dispatch(setSalaryDetails(true))}>
              <h3 className={styles.list__h3}>Зарплата за отработанные смены</h3>
              <p className={styles.list__blue}>{salary.toFixed(2)} Br</p>
              <button>
                {salaryDetails ? (
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7L7 1L13 7" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                ): (
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L13 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg> 
                )}
              </button> 
            </div>
        )}
            
          </div>
          {salaryDetails && (
            <div className={styles.details__container}>
              <div className={styles.details__list}>
                <h3 className={styles.details__list__h3}>Оклад</h3>
                <p className={styles.details__list__p}>600 Br</p>
              </div>
              <div className={styles.details__list}>
                <h3 className={styles.details__list__h3}>Премия</h3>
                <p className={styles.details__list__p}>75 Br</p>
              </div>
              <div className={styles.details__list}>
                <h3 className={styles.details__list__h3}>Премия за выручку</h3>
                <p className={styles.details__list__p}>208 Br</p>
              </div>
              <div className={styles.details__list}>
                <h3 className={styles.details__list__h3}>Чаевые</h3>
                <p className={styles.details__list__p}>40.38 Br</p>
              </div>
              <div className={styles.details__list}>
                <h3 className={styles.details__list__h3 + " " + styles.fines}>Штрафы</h3>
                <p className={styles.details__list__p + " " + styles.fines}>50 Br</p>
              </div>
            </div>
          )}
      
    </div>
  );
};
export default Description;