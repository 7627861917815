import { Profile } from '../pages/Profile';
import { MainPage } from '../pages/Main';
import { AchievementsPage } from '../pages/AchievementsPage';
import Fines from '../pages/Fine/fines';
import Offers_page from '../pages/offers/offers';
import Rating from '../pages/rating/rating';
import { ProductsPage } from '../pages/Products';
import { PossibilityPage } from '../pages/Possibilities';
import { PossibilityItemPage } from '../pages/Possibilities/components/PossibilityItemPage';
import History from '../pages/history/history';
import TimesheetPage from '../pages/TimesheetPage/timesheetPage';
import TimesheetPageId from '../pages/TimesheetPage/components/timesheetPageId ';
import { CategoriesPage } from '../pages/Categories';
import Notifications from '../pages/Notifications/notifications';

export const ROUTES = [
  {
    path: '/main',
    element: <MainPage />,
  },
  {
    path: '/achievements',
    element: <AchievementsPage />,
  },
  {
    path: '/rating',
    element: <Rating />,
  },
  {
    path: '/possibilities',
    element: <PossibilityPage />,
  },
  {
    path: '/possibilities/:id',
    element: <PossibilityItemPage />,
  },
  {
    path: '/offers',
    element: <Offers_page />,
  },
  {
    path: '/history',
    element: <History />,
  },
  {
    path: '/timesheet',
    element: <TimesheetPage />,
  },
  {
    path: '/timesheet/:id',
    element: <TimesheetPageId />,
  },
  {
    path: '/notifications',
    element: <Notifications />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/fines',
    element: <Fines />,
  },
  {
    path: '/category',
    element: <CategoriesPage />,
  },
  {
    path: '/products',
    element: <ProductsPage />,
  },
];
