import './footer.scss';

export const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-company">FCM</p>
      <div className="footer-links">
        <p>
          <a href="#">Юридическая информация</a>
        </p>
        <p>
          <a href="#">Стать партнером</a>
        </p>
        <p>
          <a href="#">Связаться с нами</a>
        </p>
        <p className="footer-review">
          <a href="#">Оставить отзыв</a>
        </p>
      </div>
    </footer>
  );
};
