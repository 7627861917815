import styles from './ModalContent.module.scss';

export const BlockGetAward = ({ number, textAlign }) => {
  const { content__done_active, content__done_disable } = styles;

  return (
    <div
      className={number > 0 ? content__done_active : content__done_disable}
      style={{ alignSelf: textAlign }}
    >
      {number > 0 ? `Получена: ${number} раз` : 'Не получена'}
    </div>
  );
};
