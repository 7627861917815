import indicators from '../../assets/indicators.svg';
import achievements from '../../assets/achievements.svg';
import rating from '../../assets/rating.svg';
import possibilities from '../../assets/possibilities.svg';
import offers from '../../assets/offers.svg';
import history from '../../assets/history.svg';
import timesheet from '../../assets/timesheet.svg';
import productCategories from '../../assets/product-categories.svg';
import notifications from '../../assets/notifications.svg';
import ru from '../../assets/ru.svg';
import eng from '../../assets/eng.svg';
import pl from '../../assets/pl.svg';

export const MENU = [
  {
    menuIcon: indicators,
    title: 'Показатели',
    path: '/main',
  },
  {
    menuIcon: achievements,
    title: 'Достижения',
    path: '/achievements',
  },
  {
    menuIcon: rating,
    title: 'Рейтинг',
    path: '/rating',
  },
  {
    menuIcon: possibilities,
    title: 'Возможности',
    path: '/possibilities',
  },
  {
    menuIcon: offers,
    title: 'Предложения',
    path: '/offers',
  },
  {
    menuIcon: history,
    title: 'История',
    path: '/history',
  },
  {
    menuIcon: timesheet,
    title: 'Табель',
    path: '/timesheet',
  },
  {
    menuIcon: productCategories,
    title: 'Категории товаров',
    path: '/category',
  },
  {
    menuIcon: notifications,
    title: 'Уведомления',
    path: '/notifications',
  },
];

export const LANGUAGES = [
  {
    langIcon: ru,
    title: 'RU',
  },
  {
    langIcon: eng,
    title: 'ENG',
  },
  {
    langIcon: pl,
    title: 'PL',
  },
];
