import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import data from "./dataCalendar.json";

export const initialState = {
  data: {},
  workedOut: 0,
  events: [],
  plan: 0,
  month: [],
  revenue: 0,
  salary: 0,
  achievement: 0,
  activeButton: "Все",
  salaryDetails: false,
  modal: false,
  dataId: {}
};

export const fetchData = createAsyncThunk("calendar/fetchData", async () => {
  
  return data;
});
export const reduserSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setEvents(state, option) {
      state.events = option.payload;
      
    },
    setMonth(state, option) {
      state.month = option.payload;
      state.workedOut = 0;
      state.plan = 0;
      state.revenue = 0;
      state.salary = 0;
      state.achievement = 0;
      let resultOut = 0;
      let resultPlan = 0;
      let resultRevenue = 0;
      let resultSalary = 0;
      let resultAchievement = 0;
      state.month.map((el) => {
        if (el.hoursWorked) {
          resultOut += el.hoursWorked;
        }
        if (el.plan) {
          resultPlan += el.plan;
        }
        if (el.revenue) {
          resultRevenue += el.revenue;
        }
        if (el.salary) {
          resultSalary += el.salary;
        }
        if (el.achievement) {
          resultAchievement += el.achievement;
        }
      })
      state.workedOut = resultOut;
      state.plan = resultPlan;
      state.revenue = resultRevenue;
      state.salary = resultSalary;
      state.achievement = resultAchievement;
    },
    setActiveButton(state, action) {
      state.activeButton = action.payload;
      state.salaryDetails = false;
    },
    setSalaryDetails(state, action) {
      state.salaryDetails = action.payload;
    },
    getModal(state, action) {
      state.modal = action.payload;
    },
    getDataId(state, action) {
      console.log(action.payload);
      state.dataId = state.data.filter(data => data.time >= action.payload.startDay && data.time <= action.payload.endDay);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload;
      });
  },
});
export default reduserSlice.reducer;
