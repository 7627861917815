import styles from './histotyContainer.module.scss';
import { useSelector } from 'react-redux'
import Bonus from './bonus';
import { useState } from 'react';

const HistotyContainer = () => {
  const [active, setActive] = useState('Все');
  function receivedButton() {
    setActive('Получено')
  }
  function allButton() {
    setActive('Все')
  }
  function spentButton() {
    setActive('Потрачено')
  }
  const { data, accrued, spent } = useSelector((state) => state.history);
  return (
    <div className={styles.history__container}>
      <div className={styles.button__container}>
        <button onClick={receivedButton} className={ active === 'Получено' ? (styles.button__received  + " " + styles.active) : styles.button__received}>Получено</button>
        <button onClick={allButton} className={active === 'Все' ? styles.button__all + " " + styles.active : styles.button__all}>Все</button>
        <button onClick={spentButton} className={active === 'Потрачено' ? styles.button__spent + " " + styles.active :  styles.button__spent}>Потрачено</button>
      </div>
      <div className={styles.scroll}>
        <div className={styles.reviewsAll__container}>
          {active == 'Все' && (
            data.map((el) => (
            <Bonus bonus={el} key={el.id} />
            ))
          )}
          {active == 'Получено' && (
            accrued.map((el) => (
            <Bonus bonus={el} key={el.id} />
            ))
          )}
          {active == 'Потрачено' && (
            spent.map((el) => (
            <Bonus bonus={el} key={el.id} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};
export default HistotyContainer;