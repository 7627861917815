import { VscClose } from 'react-icons/vsc';
import { useNavigate } from 'react-router';
import { AchieveImageBig } from '../../../components/AchieveImage/big';
import { Award } from '../../../components/Award';
import { BlockGetAward } from './BlockGetAward';
import styles from './ModalContent.module.scss';

export const ModalContent = ({ data, setActive }) => {
  const {
    content,
    content__title,
    content__name,
    content__close,
    content__icon,
    content__description,
    content__total,
    content__image,
    content__subtitle,
    content__cup,
    content__wrapper,
    content__text,
    content__progress,
    content__block,
    content__flash,
    content__data,
    content__coins,
    content__muffin,
    content__list,
    content__data_list,
  } = styles;

  const navigate = useNavigate();

  const showListGoods = () => {
    navigate('/products');
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  };

  return (
    <div className={content}>
      <div className={content__title}>
        <h2 className={content__name}>{data.title}</h2>
        <button className={content__close} onClick={setActive}>
          <VscClose className={content__icon} size={20} />
        </button>
      </div>
      <div className={content__description}>
        <div className={content__total}>
          <div className={content__image}>
            <AchieveImageBig
              progress="100"
              imgActive={data.imgActive}
              imgDisable={data.img}
            />
          </div>
          <div className={content__subtitle}>Награда при получении:</div>
          <div className={content__cup}>
            <Award
              countAwards={data.award}
              width={51}
              size={12}
              background={
                'linear-gradient(180deg,rgba(255, 255, 255, 0.14) 0%,rgba(255, 255, 255, 0.2175) 100%)'
              }
              fontWeight={500}
            />
          </div>
        </div>
        <div className={content__wrapper}>
          <div className={content__text}>{data.content}</div>
          {data.period === 'once' && <BlockGetAward number={data.count} />}
        </div>
      </div>
      {data.period === 'week' && (
        <>
          <div className={content__progress}>
            <div className={content__subtitle}>Прогресс выполнения:</div>
            {data.rating && (
              <div className={content__block}>
                <div className={content__flash}></div>
                <div className={content__data}>
                  Рейтинг в сети: {data.rating} место
                </div>
              </div>
            )}

            {data.coinsText ? (
              <div className={content__block}>
                <div className={content__coins}></div>
                <div className={content__data}>{data.coinsText}</div>
              </div>
            ) : (
              <>
                <div className={content__block}>
                  <div className={content__muffin}></div>
                  <div className={content__data}>{data.muffinText}</div>
                </div>
                <div className={content__block}>
                  <div className={content__list}></div>
                  <div
                    className={[content__data, content__data_list].join(' ')}
                    onClick={showListGoods}
                  >
                    Посмотреть список товаров
                  </div>
                </div>
              </>
            )}
          </div>
          <BlockGetAward number={data.count} textAlign={'center'} />
        </>
      )}
    </div>
  );
};
