import user from '../assets/user-photo.png';
import user1 from '../assets/user2.jpeg';
import user2 from '../assets/user3.jpeg';
import achievement from '../assets/personal-achievement.svg';

export const employeeData = {
  sum: 923.38,
  worked_time_sum: {
    month: 180,
    week: 45,
    day: 12,
  },
  salary_sum: {
    month: 600.0,
    week: 150.0,
    day: 40.0,
  },
  bonus_sum: {
    month: 125.0,
    week: 31.25,
    day: 7.81,
  },
  income_per_work_shift_sum: {
    month: 20735,
    week: 5183,
    day: 1295,
  },
  income_bonus_sum: {
    month: 208.0,
    week: 52.0,
    day: 13.0,
  },
  revenueBonusGoodsA: {
    month: 30.0,
    week: 8.0,
    day: 2.0,
  },
  revenueBonusGoodsB: {
    month: 8.0,
    week: 2.0,
    day: 1.0,
  },
  revenueBonusOtherGoods: {
    month: 170.0,
    week: 42.0,
    day: 10.0,
  },
  tips_sum: {
    month: 40.38,
    week: 10.01,
    day: 2.52,
  },
  fines_sum: {
    month: 50,
    week: 10,
    day: 2,
  },
  achievement_bonuses_sum: 15,
  salesPlanRevenueDay: 1029,
  salesFactRevenueDay: 772,
  salesPlanRevenueMonth: 18000,
  salesFactRevenueMonth: 11700,
  rating: 4.2,
  reviews: 347,
};

export const PROFILE_INFO = {
  position: 'Бариста',
  name: 'Екатерина',
  surname: 'Сергеевна',
  lastname: 'Поплавская',
  sex: 'Женский',
  birthDate: '21.05.1997',
  tel: '+37544-748-75-25',
  email: 'epoplav@gmail.com',
  userPhoto: [user, user1, user2],
  personalAchievement: achievement,
};
