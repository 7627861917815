import { useSelector } from 'react-redux';
import { useToggle } from '../../hooks/Hooks';
import store from '../../toolkitRedux';
import { mainSalarySlice } from '../../toolkitRedux/mainSalaryReducer';
import './customSelect.scss';

export const CustomSelect = ({ options }) => {
  const [isVisible, setIsVisible] = useToggle();
  const dispatch = store.dispatch;
  const { value } = useSelector((state) => state.main);
  const { changeValue } = mainSalarySlice.actions;

  const handleChoseOption = (event) => {
    dispatch(changeValue(event.target.dataset.period));
    setIsVisible();
  };

  return (
    <div className="select">
      <div className="select__value" onClick={setIsVisible}>
        <p>{value}</p>
        <div
          className={isVisible ? 'arrow arrow_down' : 'arrow arrow_up'}
        ></div>
      </div>
      <ul
        className={
          isVisible ? 'select__list' : 'select__list select__list_hide'
        }
      >
        {options.map((option) => {
          if (option !== value) {
            return (
              <li
                key={option}
                data-period={option}
                className="select__option"
                onClick={(event) => handleChoseOption(event)}
              >
                {option}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
