import { VscClose } from 'react-icons/vsc';
import Circle from './assets/circle.png';
import styles from './PossibilityItemPage.module.scss';
function getRandomArbitrary(min, max) {
  return Math.ceil(Math.random() * (max - min) + min);
}

export const ModalContentStory = ({ setActive, text }) => {
  const {
    modal__wrapper,
    modal__close,
    modal__icon,
    modal__header,
    modal__text,
  } = styles;

  const order = getRandomArbitrary(1000, 9999);

  return (
    <div className={modal__wrapper}>
      <button className={modal__close} onClick={setActive}>
        <VscClose className={modal__icon} size={30} />
      </button>
      <div className={modal__header}>
        <p>Заказ на товар</p>
        <p>
          {text} №{order}
        </p>
        <p>успешно размещен</p>
      </div>
      <img src={Circle} alt="" />
      <div className={modal__text}>
        В ближайшее время с вами свяжется наш менеджер для уточнения деталей
      </div>
    </div>
  );
};
