import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getStartAndEndData } from '../pages/Main/helpers/getPeriod';

export const initialState = {
  data: {},
  value: 'За месяц',
};

export const fetchSalaryData = createAsyncThunk(
  'main/fetchSalaryData',
  async ({ value, userId }) => {
    const { start_date, end_date } = getStartAndEndData(value);
    const res = await fetch('https://dev.fcm.by/hr/set_data', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: userId,
        start_date: start_date,
        end_date: end_date,
      }),
    });
    const data = await res.json();

    return data;
  }
);

export const mainSalarySlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    changeValue(state, option) {
      state.value = option.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalaryData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default mainSalarySlice.reducer;
