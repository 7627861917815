import styles from './stars.module.scss';

const Stars = ({number}) => {
  const y = (number * 100 / 5);
  const x = y + '%';
  return (
    <div className={styles.stars__container}>
      <div className={styles.svg__grey}>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
      </div>
      <div className={styles.line}  style={{ width: x}}>
        <div className={styles.svg__gold}>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
        <svg className={styles.svg} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9635 6.26973C13.8718 5.97032 13.6204 5.75767 13.3229 5.72935L9.28214 5.34184L7.68431 1.3919C7.56649 1.10242 7.29817 0.915039 7.00006 0.915039C6.70194 0.915039 6.43362 1.10242 6.31581 1.39258L4.71797 5.34184L0.676558 5.72935C0.379615 5.75835 0.128816 5.97032 0.0366355 6.26973C-0.0555451 6.56914 0.0295857 6.89754 0.254216 7.10455L3.30856 9.93368L2.40791 14.1239C2.342 14.432 2.45523 14.7505 2.69727 14.9353C2.82737 15.0345 2.97958 15.0851 3.13307 15.0851C3.26541 15.0851 3.39668 15.0474 3.5145 14.9729L7.00006 12.7727L10.4843 14.9729C10.7393 15.1349 11.0607 15.1202 11.3022 14.9353C11.5444 14.7499 11.6575 14.4313 11.5916 14.1239L10.6909 9.93368L13.7453 7.10511C13.9699 6.89754 14.0557 6.5697 13.9635 6.26973Z" />
        </svg>
      </div>
      </div>
    </div>
    
  );
};
export default Stars;