import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Authorization from '../pages/authorization/Authorization';
import Homepage from '../pages/homepage/homepage';
import { PrivateAuth } from '../hoc/PrivateAuth';
import { ROUTES } from '../constants/routes';

const AppRoutes = () => {
  const { auth } = useSelector((state) => state.auth);

  const routeComponents = ROUTES.map(({ path, element }, key) => (
    <Route
      path={path}
      element={<PrivateAuth>{element}</PrivateAuth>}
      key={key}
    />
  ));

  return (
    <Routes>
      <Route path="/" element={<Homepage />}></Route>
      <Route
        path="/login"
        element={!auth ? <Authorization /> : <Navigate to={'/main'} />}
      ></Route>
      {routeComponents}
    </Routes>
  );
};

export default AppRoutes;
