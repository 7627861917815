import { useEffect } from 'react';
import { CustomSelect } from '../../components/CustomSelect';
import { useToggle } from '../../hooks/Hooks';
import { RatingBlock } from './components/RatingBlock';
import { SalaryDetails } from './components/SalaryDetails';
import styles from './Main.module.scss';
import { Slides } from './components/Slides';
import { achievementsData } from '../AchievementsPage/index.jsx';
import store from '../../toolkitRedux';
import { useSelector } from 'react-redux';
import { fetchSalaryData } from '../../toolkitRedux/mainSalaryReducer';
import { Loader } from '../../components/Loader';

export const MainPage = () => {
  const {
    salary,
    salary__wrapper,
    salary__title,
    salary__total,
    progress,
    progress__container,
    progress__title,
  } = styles;

  const dispatch = store.dispatch;
  const { data, value } = useSelector((state) => state.main);
  const { isLoaded, userId } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchSalaryData({ value: value, userId: userId }));
  }, [value, dispatch, userId]);

  const dataOptions = ['За месяц', 'За неделю', 'За день'];

  const [isVisibleSalary, setIsVisibleSalary] = useToggle();

  return (
    <main className="main">
      {isLoaded ? (
        <Loader />
      ) : (
        <>
          <section className={salary}>
            <div className="container">
              <div className={salary__wrapper}>
                <h2 className="title">Показатели</h2>
                <CustomSelect options={dataOptions} />
              </div>
              <div className={salary__title} onClick={setIsVisibleSalary}>
                <div className={salary__total}>{data.total_sum} Br</div>
                <div
                  className={
                    isVisibleSalary ? 'arrow arrow_down' : 'arrow arrow_up'
                  }
                ></div>
              </div>
            </div>
          </section>
          <SalaryDetails showBlock={isVisibleSalary} selectValue={value} />
          <RatingBlock />
          <section className={progress}>
            <div className={['container', progress__container].join(' ')}>
              <h3 className={['title', progress__title].join(' ')}>
                Прогресс в достижениях
              </h3>
              <Slides slides={achievementsData} />
            </div>
          </section>
        </>
      )}
    </main>
  );
};
