import styles from './Categories.module.scss';

import Cake from './assets/cake.png';
import Donuts from './assets/donuts.png';
import Pizza from './assets/pizza.png';
import Cup from './assets/cup.png';
import { useState } from 'react';

const categoryData = [
  {
    id: 11,
    name: 'Чизкейки и торты',
    imageSrc: Cake,
    category: 'B',
  },
  {
    id: 22,
    name: 'Донаты',
    imageSrc: Donuts,
    category: 'A',
  },
  {
    id: 33,
    name: 'Пицца',
    imageSrc: Pizza,
    category: 'B',
  },
  {
    id: 99,
    name: 'Кофе',
    imageSrc: Cup,
    category: 'Other',
  },
];

export const CategoriesPage = () => {
  const CategoriesGroups = [
    {
      id: 1,
      title: 'Товары категории “А”',
      category: 'A',
      bonus: 3,
      list: [...categoryData.filter((item) => item.category === 'A')],
    },
    {
      id: 2,
      title: 'Товары категории “В”',
      category: 'B',
      bonus: 2,
      list: [...categoryData.filter((item) => item.category === 'B')],
    },
    {
      id: 3,
      title: 'Прочие товары',
      category: 'Other',
      bonus: 2,
      list: [...categoryData.filter((item) => item.category === 'Other')],
    },
  ];
  const {
    category,
    category__list,
    category__item,
    category__arrow,
    category__arrow_down,
    category__show,
    category__hide,
    category__items,
    category__bonus,
    category__name,
    category__name_item,
    category__title,
    category__header,
  } = styles;

  const [isOpenCategoryGroup, setIsOpenCategoryGroup] = useState({
    A: false,
    B: false,
    Others: false,
  });

  const changeOpenCategoryGroup = (category) => {
    setIsOpenCategoryGroup({
      ...isOpenCategoryGroup,
      [`${category}`]: !isOpenCategoryGroup[`${category}`],
    });
  };

  return (
    <main className="main">
      <section className={category}>
        <div className="container">
          <div className={category__title}>Категории товаров</div>
          <ul className={category__list}>
            {CategoriesGroups.map((category) => (
              <div key={category.id}>
                <li
                  className={category__item}
                  onClick={() => changeOpenCategoryGroup(category.category)}
                >
                  <div className={category__header}>
                    <p className={category__name}>{category.title}</p>
                    <p className={category__bonus}>
                      В случае реализации данных товаров премия составит{' '}
                      {category.bonus}%
                    </p>
                  </div>
                  <div
                    className={
                      isOpenCategoryGroup[category.category]
                        ? [category__arrow, category__arrow_down].join(' ')
                        : category__arrow
                    }
                  ></div>
                </li>
                <ul
                  className={
                    isOpenCategoryGroup[category.category]
                      ? category__show
                      : category__hide
                  }
                  key={category.category + category.id}
                >
                  {category.list.map((item) => (
                    <>
                      <li key={item.id} className={category__items}>
                        <img src={item.imageSrc} alt="Category image" />
                        <p className={category__name_item}>{item.name}</p>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            ))}
          </ul>
        </div>
        {/* </div> */}
      </section>
    </main>
  );
};
