import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  token: null,
  error: null,
  userData: null,
};


export const reduserSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    cleanError: (state) => {
      state.error = null;
    },
  }
});
export default reduserSlice.reducer;
