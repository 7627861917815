import styles from './PossibilityItemPage.module.scss';
import { Band } from './Band';

export const Bands = ({ slidesCount, slideNumber }) => {
  const { bands } = styles;

  const renderBands = () => {
    const bands = [];

    for (let i = 0; i < slidesCount; i++) {
      bands.push(
        <Band
          key={`band-${i}`}
          number={i}
          slidesCount={slidesCount}
          slideNumber={slideNumber}
        />
      );
    }

    return bands;
  };

  return <div className={bands}>{renderBands()}</div>;
};
