import styles from './ProgressBarCircle.module.scss';

export const ProgressBarCircle = (props) => {
  let { progress = 0 } = props;

  const widthCircle = 92;
  const borderWidth = 13;
  const center = widthCircle / 2;
  const radius = center - borderWidth - 3;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <div className={styles['progress-bar']}>
      <svg className={styles['progress-bar__svg']}>
        <circle
          className={styles['progress-bar__track']}
          cx={center}
          cy={center}
          r={radius}
        />
        <circle
          className={styles['progress-bar__indicator']}
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </svg>
      <div className={styles['progress-bar__label']}>
        <span
          className={styles['progress-bar__progress']}
        >{`${progress} %`}</span>
      </div>
    </div>
  );
};
