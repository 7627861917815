import { useNavigate, useParams } from 'react-router';
import styles from './PossibilityItemPage.module.scss';
import lesson from './assets/lesson1.png';
import lesson2 from './assets/lesson2.png';
import lesson3 from './assets/lesson3.png';
import backpack from './assets/backpack1.png';
import backpack2 from './assets/backpack.png';
import casePhoto from './assets/case1.png';
import casePhoto1 from './assets/phoneCase.png';
import coach from './assets/coach.png';
import { Bands } from './Bands';
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiperPossibilities.scss';
import 'swiper/less/navigation';
import 'swiper/less/pagination';
import { useState } from 'react';
import { RiDoubleQuotesL } from 'react-icons/ri';
import { Award } from '../../../components/Award';
import { SelectPossibility } from './SelectPossibility';
import { Modal } from '../../../components/Modal/Modal';
import { ModalContentStory } from './ModalContentStory';
import { useSelector } from 'react-redux';

const dataPossibility = [
  {
    id: 1,
    title: 'Урок Латте-арт',
    img: [lesson, lesson2, lesson3],
    place: 'Проводится в компании Бариста.бел',
    advantages: [
      '4 занятия/8 академ. часов',
      'Индивидуально',
      'График занятий подстраиваем под вас',
      'Сертификат: на русском или английском',
    ],
    description: {
      title: 'О курсе',
      content: `"Курс идеально подходит для вас, если у вас уже есть опыт в приготовлении кофе
         на профессиональном оборудовании и вы хотите подтянуть свои практические навыки
          в молочных кофейных напитках."`,
    },
    coach: {
      img: coach,
      nation: 'Тренер Белоус',
      words: `На занятиях так подаю материал, 
      чтобы ученику любого возраста была 
      понятна теория и практика.
      На каждом занятии делаю упор 
      на настройку помола 
      и приготовление эспрессо.`,
    },
    programm: {
      title: 'Программа:',
      content: [
        'Теория вливания молока и создания напитков при помощи техники латте-арта',
        'Как размер и форма посуды влияют на взбивание молока и технику его вливания',
        'Как правильно выбирать молоко для кофейных напитков',
        'Основные приемы латте-арт: этчинг и питчинг',
        'Практика приготовления напитков с использованием базовых видов латте-арта',
        'Сертификат об окончании курса',
      ],
    },
    text: 'Урок Латте-арт',
  },

  {
    id: 2,
    title: 'Рюкзак',
    img: [backpack, backpack2],
    text: 'Рюкзак',
  },
  {
    id: 3,
    title: 'Чехол на iPhone',
    img: [casePhoto1, casePhoto],
    text: 'Чехол на iPhone',
  },
];

export const PossibilityItemPage = () => {
  const {
    possibility,
    possibility__header,
    possibility__back,
    possibility__content,
    swiper__wrapper,
    possibility__title,
    possibility__advantages,
    possibility__description,
    possibility__text,
    possibility__coach,
    possibility__photo,
    possibility__title_coach,
    possibility__words,
    possibility__quotesL,
    possibility__programm,
    possibility__advantage,
    possibility__cost,
    possibility__bonuses,
    possibility__payment,
    possibility__payment_cost,
    possibility__btn,
  } = styles;

  const { id } = useParams();
  const navigate = useNavigate();
  const [slideNumber, setSlideNumber] = useState(0);

  const itemId = +id.replace(':', ' ').trim();
  const { possibilities } = useSelector((state) => state.possibility);

  const possibilityById = possibilities.filter(
    (possibility) => possibility.id === itemId
  );

  // const text = () => {
  //   console.log(possibilityById);
  //   const textArr = possibilityById[0].full_description.split('\r\n');
  //   console.log(textArr);
  // };

  const item = dataPossibility.find(
    (item) => item.title === possibilityById[0].title
  );

  const goBack = () => {
    navigate(-1);
  };

  const changeActiveSlider = (number) => {
    setSlideNumber(number);
    // text();
  };

  const options = ['Бонусными баллами', 'Самостоятельная оплата'];
  const [value, setValue] = useState('Способ оплаты');

  const changeValue = (event) => {
    setValue(event.target.dataset.value);
  };

  const [modalActive, setModalActive] = useState(false);

  const openModal = () => {
    setModalActive(true);
    document.body.classList.add('block');
    document.body.style.touchAction = 'none';
  };

  const closeModal = () => {
    setModalActive(false);
    document.body.classList.remove('block');
    navigate('/possibilities');
    document.body.style.touchAction = 'auto';
  };

  return (
    <main className="main">
      <div className="container">
        <section className={possibility}>
          <div className={possibility__header}>
            <div className={possibility__back} onClick={goBack}></div>
            {item ? <h1>{item.title}</h1> : null}
          </div>
          {item ? (
            <div className={possibility__content}>
              <div className={swiper__wrapper}>
                <Swiper
                  slidesPerView={'auto'}
                  spaceBetween={20}
                  pagination={{
                    clickable: true,
                  }}
                  className="possibilitySwiper"
                  onSlideChange={(event) =>
                    changeActiveSlider(event.activeIndex)
                  }
                >
                  {item.img.map((image, ind) => (
                    <SwiperSlide key={ind}>
                      <img src={image} alt="img" className="avatar" />
                    </SwiperSlide>
                  ))}
                  <Bands
                    slidesCount={item.img.length}
                    slideNumber={slideNumber}
                  />
                </Swiper>
              </div>

              {item.place ? (
                <div className={possibility__advantages}>
                  <h4 className={possibility__title}>{item.place}</h4>
                  <ul className={possibility__advantage}>
                    {item.advantages.map((advantage, ind) => (
                      <li key={ind}>{advantage}</li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {item.description ? (
                <div className={possibility__description}>
                  <h4 className={possibility__title}>
                    {item.description.title}
                  </h4>
                  <div className={possibility__text}>
                    {item.description.content}
                  </div>
                </div>
              ) : null}

              {item.coach ? (
                <div className={possibility__coach}>
                  <div
                    style={{ backgroundImage: `url(${item.coach.img})` }}
                    className={possibility__photo}
                  />
                  <h4
                    className={[
                      possibility__title,
                      possibility__title_coach,
                    ].join(' ')}
                  >
                    {item.coach.nation}
                  </h4>
                  <div className={possibility__words}>
                    <RiDoubleQuotesL
                      color="#ebebeb"
                      className={possibility__quotesL}
                    />
                    <blockquote>{item.coach.words}</blockquote>
                  </div>
                </div>
              ) : null}

              {item.programm ? (
                <div className={possibility__description}>
                  <h4 className={possibility__title}>{item.programm.title}</h4>
                  <ul className={possibility__programm}>
                    {item.programm.content.map((programm, ind) => (
                      <li key={ind}>{programm}</li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <div>
                <h4 className={possibility__title}>Стоимость курса:</h4>
                <div
                  className={[possibility__cost, possibility__bonuses].join(
                    ' '
                  )}
                >
                  <div>Бонусными баллами:</div>
                  <Award
                    countAwards={possibilityById[0].value_in_points}
                    width={50}
                    size={14}
                    background={'#2D2D2E'}
                  />
                </div>
                <div className={possibility__cost}>
                  <div>При самостоятельной оплате:</div>
                  <div>{possibilityById[0].value_in_money} Br</div>
                </div>
              </div>

              <div>
                <SelectPossibility
                  options={options}
                  checkedOption={value}
                  onChangeOption={changeValue}
                  countAwards={possibilityById[0].value_in_points}
                />
                {value === 'Бонусными баллами' && (
                  <div className={possibility__payment}>
                    <Award
                      countAwards={possibilityById[0].value_in_points}
                      width={50}
                      size={14}
                      background={'#2D2D2E'}
                    />
                    <p>Бонусных балла</p>
                  </div>
                )}
                {value === 'Самостоятельная оплата' && (
                  <div className={possibility__payment}>
                    <p className={possibility__payment_cost}>
                      {possibilityById[0].value_in_money} Br
                    </p>
                    <p>Самостоятельная оплата</p>
                  </div>
                )}
                {value !== 'Способ оплаты' && (
                  <button className={possibility__btn} onClick={openModal}>
                    Заказать
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>Товар не найден</div>
          )}
        </section>
      </div>
      <Modal
        activeModal={modalActive}
        setActive={setModalActive}
        bg={'#202021'}
        width={'100%'}
      >
        {modalActive && (
          <ModalContentStory setActive={closeModal} text={item.text} />
        )}
      </Modal>
    </main>
  );
};
