import { useNavigate } from 'react-router-dom';

import { MENU, LANGUAGES } from './constants';

import styles from './menu.module.scss';
import goOut from '../../assets/go-out.svg';
import { useState } from 'react';
import ModalExit from '../ModalExit/modalExit';

export const Menu = ({ setIsOpen }) => {
  const navigate = useNavigate();
  const [modalExit, setModalExit] = useState(false);
  const closeModal = () => {
    setModalExit(false);
  };

  const handleNavigate = (path) => () => {
    navigate(path);
    setIsOpen(false);
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  };

  const openModalWindow = () => {
    setModalExit(true);
  };

  return (
    <nav className={styles.menu} onClick={closeMenu}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <ul>
          {MENU.map(({ menuIcon, title, path }) => (
            <li
              key={title}
              className={styles.menuWrapper}
              onClick={handleNavigate(path)}
            >
              <div className={styles.iconWrapper}>
                <img src={menuIcon} alt="menuIcon" />
              </div>
              <div className={styles.menuTitle}>{title}</div>
            </li>
          ))}
          <li
            key="Выйти из системы"
            className={styles.menuWrapper}
            onClick={openModalWindow}
          >
            <div className={styles.iconWrapper}>
              <img src={goOut} alt="menuIcon" />
            </div>
            <div className={styles.menuTitle}>Выйти из системы</div>
          </li>
        </ul>

        <ul className={styles.languages}>
          {LANGUAGES.map(({ langIcon, title }) => (
            <li key={title} className={styles.languagesWrapper}>
              <img
                src={langIcon}
                alt="langIcon"
                className={styles.languageIcon}
              />
              <div className={styles.languageTitle}>{title}</div>
            </li>
          ))}
        </ul>
      </div>
      {modalExit && <ModalExit closeModal={closeModal} />}
    </nav>
  );
};
