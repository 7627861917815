import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import data from "./dataHistory.json";
export const initialState = {
  data: {},
  points: 0,
  accrued: [],
  spent: []
};

export const fetchData = createAsyncThunk("history/fetchData", async () => {
  return data;
});
export const reduserSlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    removeDetails(state, action) {
      console.log(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload;
      const accrued = action.payload.filter(el => el.spent == false)
      state.accrued = accrued;
      const spent = action.payload.filter(el => el.spent == true)
      state.spent = spent;
      let points = 0;
      accrued.map((el) => {
        points += el.points;
      })
      
      let pointSpent = 0;
      spent.map((el) => {
        pointSpent += el.points
      })
      state.points = points - pointSpent;
    });
  },
});
export default reduserSlice.reducer;
