import styles from '../profile.module.scss';

export const Band = ({ number, slideNumber, slidesCount }) => {
  const { band, selected } = styles;
  const width = 100 / slidesCount;
  return (
    <div
      style={{ width: `${width}%` }}
      className={slideNumber === number ? [band, selected].join(' ') : band}
    />
  );
};
