export const parametersForSvg = (progress, widthCircle) => {
  const borderWidth = 2;
  const center = widthCircle / 2;
  const radius = center - borderWidth;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset =
    progress <= 100
      ? dashArray * ((100 - progress) / 100)
      : dashArray * ((100 - (progress - 100)) / 100);
  const dashOffsetLine =
    progress <= 100
      ? 390 * ((100 - progress) / 100)
      : 390 * ((100 - (progress - 100)) / 100);

  return { center, radius, dashOffset, dashArray, dashOffsetLine };
};
