import styles from './modal.module.scss';
import  './block.scss';
import { useSelector } from 'react-redux'
import store from "../../../toolkitRedux/index"
import { reduserSlice } from '../../../toolkitRedux/taringReducer';


const Modal = ({ review }) => {
  const { modal } = useSelector((state) => state.taring);
  const dispatch = store.dispatch;
  const { closeModal } = reduserSlice.actions;
  function close(){
    dispatch(closeModal());
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  }

  console.log(review);
  return (
    <div className={modal ? styles.modal__container + " " + styles.active : styles.modal__container}>
      <div className={styles.modal}>
        <div className={styles.close}>
          <p className={styles.close__title}>Заказ {review.Order.number} от {review.date}</p>
          <button className={styles.button__close} onClick={close}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6 6L18 18" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        <div className={styles.menu__container}>
          {review.Order.list.map((list) => (
            <div className={styles.menu__list} key={list.id}>
              <div className={styles.menu__info__container}>
                <img className={styles.menu__img} src={list.img} alt="menu" />
                <div className={styles.menu__info}>
                  <p className={styles.menu__name}>{list.name}</p>
                  <p className={styles.menu__quantity}>{list.quantity} шт</p>
                </div>
              </div>
              <p className={styles.menu__price}>{list.price} Br </p>
            </div>
          ))}
        </div>
        <div className={styles.sum__container}>
          <p className={styles.sum__title}>Итоговая стоимость</p>
          <p className={styles.sum}>{review.Order.sum} Br </p>
        </div>
        <div className={styles.info__container}>
          <div className={styles.info}>
            <p className={styles.info__title}>Размещен</p>
            <p className={styles.info__date}>{review.Order.time1}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.info__title}>Время желаемого получения</p>
            <p className={styles.info__date}>{review.Order.time2}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.info__title}>Принят в работу</p>
            <p className={styles.info__date}>{review.Order.time3}</p>
          </div>
          <div className={styles.info}>
            <p className={styles.info__title}>Передан клиенту</p>
            <p className={styles.info__date}>{review.Order.time4}</p>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Modal;