import styles from './review.module.scss';
import Stars from './stars';
import { useState } from "react";
import Modal from './modal';
import store from "../../../toolkitRedux/index"
import { reduserSlice } from '../../../toolkitRedux/taringReducer';
import { useSelector } from 'react-redux'
import { useEffect } from 'react';


const Review = ({ review }) => {
  const [details, setDetails] = useState(false);
  const { idModal, optionData } = useSelector((state) => state.taring);
  const dispatch = store.dispatch;
  const { addModal, addIdModal } = reduserSlice.actions;


  function Details() {
    if (!details) {
      setDetails(true);
    } else {
      setDetails(false);
    }
  }
  useEffect(() => {
    setDetails(false);
  }, [optionData])
  
  function getModal(event) {
    dispatch(addModal())
    const id = event.target.dataset.id;
    dispatch(addIdModal(id))
    document.body.classList.add('block');
    document.body.style.touchAction = 'none';
  }
  return (
    <div className={details ? styles.review__container__details : styles.review__container} onClick={Details}>
      <div className={details ? styles.review__maim__container__details : styles.review__maim__container}>
        <div className={styles.review__main}>
          <p className={details ? styles.review__date__details : styles.review__date}>{review.date}</p>
          <p className={details ? styles.review__review__details : styles.review__review}>Оценка</p>
          <Stars number={review.Grade}/>
        </div>
        {details ? (
          <svg className={styles["fine__button-svg"]} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L7 1L13 7" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          ) : (
          <svg className={styles["fine__button-svg"]} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )}
      </div>
      
      {details && (
        <div className={styles.list__container}>
          <div className={styles.item}>
            <p className={styles.details__title}>Клиент</p>
            <p className={styles.details__p}>{review.Client}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.details__title}>Отзыв</p>
            <p className={styles.details__p}>{review.Review}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.details__title}>Кафе</p>
            <p className={styles.details__p}>{review.Cafe}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.details__title + " " + styles.details}>Детали заказа</p>
          </div>
          <div className={styles.item}>
            <p className={styles.details__title}>Старший бариста</p>
            <p className={styles.details__p}>{review.Senior__barista}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.details__title}>Бариста</p>
            <p className={styles.details__p}>{review.Barista}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.details__title}>Заказ</p>
            <p className={styles.details__p}>
              <span
                onClick={(event) => getModal(event)}
                className={styles.details__review}
                data-id={review.id}
              >{review.Order.number}</span> &nbsp; на сумму {review.Order.sum} Br
            </p>
          </div>
        </div>
      )}
      {review.id == idModal ? <Modal review={review} /> : null  }
    </div>
  );
};
export default Review;