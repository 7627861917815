import styles from './monitor.module.scss';
import store from '../../../toolkitRedux/index'
import { reduserSlice } from '../../../toolkitRedux/calendarReducer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

const Monitor = ({ today, next, prev }) => {
  const { setActiveButton, getModal } = reduserSlice.actions;
  const dispatch = store.dispatch;
  const { activeButton, modal } = useSelector((state) => state.calendar);
  const [yearModal, setYearModal] = useState(today.format('YYYY'));
  console.log(today.format('MMMM'))
  const month = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
  useEffect(() => {
    if (modal) {
      document.body.classList.add('block');
      document.body.style.touchAction = 'none';
    } else {
      document.body.classList.remove('block');
      document.body.style.touchAction = 'auto';
    }
  }, [modal])

  const [monthNow, setMonthNow] = useState(today.format('MMMM'));
  useEffect(() => {
    setMonthNow(today.format('MMMM'))
  }, [today])
  useEffect(() => {
    switch (monthNow) {
      case 'January':
        setMonthNow('Январь');
        break;
      case 'February':
        setMonthNow('Февраль');
        break;
      case 'March':
        setMonthNow('Март');
        break;
      case 'April':
        setMonthNow('Апрель');
        break;
      case 'May':
        setMonthNow('Май');
        break;
      case 'June':
        setMonthNow('Июнь');
        break;
      case 'July':
        setMonthNow('Июль');
        break;
      case 'August':
        setMonthNow('Август');
        break;
      case 'September':
        setMonthNow('Сентябрь');
        break;
      case 'October':
        setMonthNow('Октябрь');
        break;
      case 'November':
        setMonthNow('Ноябрь');
        break;
      case 'December':
        setMonthNow('Декабрь');
        break;
      default:
        
    }
  }, [monthNow])
  return (
    <>
      <div className={styles.button__change__container}>
        <button onClick={() => dispatch(setActiveButton('Все'))} className={activeButton == 'Все' ? styles.button__change + " " + styles.active : styles.button__change}>Все</button>
        <button onClick={() => dispatch(setActiveButton('Смены'))} className={activeButton == 'Смены' ? styles.button__change + " " + styles.active : styles.button__change}>Смены</button>
        <button onClick={() => dispatch(setActiveButton('Зарплата'))} className={activeButton == 'Зарплата' ? styles.button__change + " " + styles.active : styles.button__change}>Зарплата</button>
      </div>
      <div className={styles.timesheet__container}>
        <button onClick={prev} className={styles.button}>
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 13L0.5 7L6.5 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <div className={styles.button__change__today} onClick={() => dispatch(getModal(true))}>
          <p>{monthNow}</p>
          <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.56176 6.18298H12.4444M9.46138 8.91679H9.46756M6.50308 8.91679H6.50925M3.53859 8.91679H3.54477M9.46138 11.6374H9.46756M6.50308 11.6374H6.50925M3.53859 11.6374H3.54477M9.19581 1V3.30355M3.81034 1V3.30355M9.32551 2.10544H3.68064C1.72285 2.10544 0.5 3.25059 0.5 5.35556V11.6903C0.5 13.8284 1.72285 15 3.68064 15H9.31933C11.2833 15 12.5 13.8482 12.5 11.7433V5.35556C12.5062 3.25059 11.2895 2.10544 9.32551 2.10544Z" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
        <button onClick={next} className={styles.button}>
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 13L6.5 7L0.499999 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
      {modal && (
        <div className={styles.selection__box__container} onClick={() => dispatch(getModal(false))}>
          <div className={styles.selection__box} onClick={(e) => e.stopPropagation()}>
            <div className={styles.button__container__box}>
              <button className={styles.button__box} onClick={() => setYearModal(yearModal-1)}>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 13L1 7L7 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
              <p>{yearModal}</p>
              <button className={styles.button__box} onClick={() => setYearModal(yearModal+1)}>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L0.999999 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
            <div className={styles.month__container}>
              {month.map((el) => (
                <button className={styles.button__month} key={el} onClick={() => dispatch(getModal(false))}>{el}</button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Monitor;