import { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

import styles from './googleAuth.module.scss';
import store from '../../../toolkitRedux';
import { fetchAuthGoogleData } from '../../../toolkitRedux/authReducer';

function GoogleAuth() {
  const dispatch = store.dispatch;
  const [token, setToken] = useState();
  const [email, setEmail] = useState();

  const setGoogleAuthToken = async (response) => {
    const { email } = jwt_decode(response.credential);
    setEmail(email);
    setToken(response.credential);
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchAuthGoogleData({email, token}));
    }
  }, [dispatch, token]);

  return (
    <div className={styles.wrapper}>
      <GoogleLogin
        className={styles.google__container}
        onSuccess={(resp) => {
          setGoogleAuthToken(resp);
        }}
        logo_alignment="left"
        shape="circle"
        size="medium"
        theme="filled_black"
        text="signin_with"
        locale="ru-Ru"
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
}

export default GoogleAuth;
