import styles from './ProgressBarLine.module.scss';

export const ProgressBarLine = ({ progress, marginTop, marginBottom }) => {
  const currentProgress = progress <= 100 ? progress : progress - 100;

  const childStyle = {
    width: `${currentProgress}%`,
  };

  const textStyle = {
    left: `${currentProgress < 10 ? currentProgress : currentProgress - 10}%`,
  };

  return (
    <div
      className={styles['progressbar-line']}
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
    >
      <div className={styles['progressbar-line__child']} style={childStyle}>
        <span
          className={styles['progressbar-line__text']}
          style={textStyle}
        >{`${currentProgress}%`}</span>
      </div>
    </div>
  );
};
