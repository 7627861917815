import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
export const initialState = {
  token: localStorage.getItem('token')?.replace(/"/g, '') || null,
  error: null,
  userId: localStorage.getItem('userId')?.replace(/"/g, '') || null,
  auth: localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth'))
    : false,
  isLoaded: false,
};

export const fetchAuthGoogleData = createAsyncThunk(
  'auth/fetchAuthGoogleData',
  async (body) => {
    const res = await fetch('https://dev.fcm.by/auth/hr/google/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = await res.json();

    if (res.status !== 200) {
      throw new Error('No authorized');
    }

    return {
      userId: data.user_id,
      email: data.email,
      token: data.access_token,
      refresh_token: data.refresh_token,
    };
  }
);

export const fetchAuthEmailData = createAsyncThunk(
  'auth/fetchAuthEmailData',
  async (body) => {
    const res = await fetch('https://dev.fcm.by/auth/login/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = await res.json();

    if (res.status !== 200) {
      throw new Error('No authorized');
    }

    return {
      userId: data.user_id,
      email: data.email,
      token: data.access_token,
      refresh_token: data.refresh_token,
    };
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeStatusAuth(state, action) {
      state.auth = action.payload;
      localStorage.setItem('auth', JSON.stringify(action.payload));
    },
    changeLoaderStatus(state, action) {
      state.isLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthGoogleData.pending, (state) => {
        state.isLoaded = true;
      })
      .addCase(fetchAuthGoogleData.fulfilled, (state, action) => {
        state.isLoaded = false;
        state.userId = action.payload.userId;
        state.token = action.payload.token;
        state.auth = true;
        localStorage.setItem('userId', state.userId);
        localStorage.setItem('token', state.token);
        localStorage.setItem('refresh_token', state.refresh_token);
        localStorage.setItem('auth', state.auth);
      })
      .addCase(fetchAuthGoogleData.rejected, (state) => {
        state.isLoaded = false;
        state.error = true;
        state.auth = false;
      })
      .addCase(fetchAuthEmailData.pending, (state) => {
        state.isLoaded = true;
      })
      .addCase(fetchAuthEmailData.fulfilled, (state, action) => {
        state.isLoaded = false;
        state.userId = action.payload.userId;
        state.token = action.payload.token;
        state.auth = true;
        localStorage.setItem('userId', state.userId);
        localStorage.setItem('token', state.token);
        localStorage.setItem('refresh_token', state.refresh_token);
        localStorage.setItem('auth', state.auth);
      })
      .addCase(fetchAuthEmailData.rejected, (state) => {
        state.isLoaded = false;
        state.error = true;
        state.auth = false;
      });
  },
});

export const { changeStatusAuth, changeLoaderStatus } = authSlice.actions;
export default authSlice.reducer;
