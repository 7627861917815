import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Award } from '../../../components/Award';
import styles from './SalaryDetails.module.scss';

export const SalaryDetails = ({ showBlock }) => {
  const { data } = useSelector((state) => state.main);

  const salaryData = [
    {
      indicator: 'Отработано по факту',
      value: `${data.worked_time_sum} часов`,
      className: styles['salary-details__row'],
      pathToLink: '/timesheet',
    },
    {
      indicator: 'Оклад',
      value: `${data.salary_sum} Br`,
      className: [
        styles['salary-details__row'],
        styles['salary-details__row_mark'],
      ].join(' '),
    },
    {
      indicator: 'Премия',
      value: `${data.bonus_sum} Br`,
      className: [
        styles['salary-details__row'],
        styles['salary-details__row_mark'],
      ].join(' '),
    },
    {
      indicator: 'Выручка за рабочие смены',
      value: `${data.income_per_work_shift_sum} Br`,
      className: styles['salary-details__row'],
    },
    {
      indicator: 'Премия за выручку',
      value: `${data.income_bonus_sum} Br`,
      className: [
        styles['salary-details__row'],
        styles['salary-details__row_mark'],
      ].join(' '),
      pathToLink: '/category',
    },
    {
      indicator: 'Чаевые',
      value: `${data.tips_sum} Br`,
      className: [
        styles['salary-details__row'],
        styles['salary-details__row_mark'],
      ].join(' '),
    },
    {
      indicator: 'Итого:',
      value: `${data.income_total_sum} Br`,
      className: styles['salary-details__row'],
    },
    {
      indicator: 'Штрафы и удержания',
      value: `${data.fines_sum} Br`,
      className: [
        styles['salary-details__row'],
        styles['salary-details__row_fine'],
      ].join(' '),
      pathToLink: '/fines',
    },
  ];

  const indexButton = [0, 2, 4, 5, 7];

  return (
    <section
      className={
        showBlock
          ? [styles['salary-details'], styles['salary-details_visible']].join(
              ' '
            )
          : [styles['salary-details'], styles['salary-details_hidden']].join(
              ' '
            )
      }
    >
      <div className={styles['salary-details__wrapper']}>
        <div className="container">
          <div className={styles['salary-details__title']}>
            <p className={styles['salary-details__indicator']}>Позиция</p>
            <p>Показатель</p>
          </div>
          {salaryData.map((item, index) => {
            if (indexButton.includes(index)) {
              return (
                <div key={index} className={item.className}>
                  <p className={styles['salary-details__indicator']}>
                    {item.indicator}
                  </p>
                  <Link
                    to={item.pathToLink}
                    className={styles['salary-details__button']}
                  >
                    <p className={styles['salary-details__value']}>
                      {item.value}
                    </p>
                    <div className="arrow arrow_right"></div>
                  </Link>
                </div>
              );
            }
            return (
              <div key={index} className={item.className}>
                <p className={styles['salary-details__indicator']}>
                  {item.indicator}
                </p>
                <p className={styles['salary-details__value']}>{item.value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles['salary-details__total-wrapper']}>
        <div className="container">
          <div
            className={[
              styles['salary-details__row'],
              styles['salary-details__row_total'],
            ].join(' ')}
          >
            <p className={styles['salary-details__indicator']}>
              Итого к начислению:
            </p>
            <p className={styles['salary-details__value']}>
              {data.total_sum} Br
            </p>
          </div>
          <div
            className={[
              styles['salary-details__row'],
              styles['salary-details__row_achievement'],
            ].join(' ')}
          >
            <p className={styles['salary-details__indicator']}>
              Бонусы за достижения
            </p>
            <div className={styles['salary-details__cup']}>
              <Award
                countAwards={data.achievement_bonuses_sum}
                width={54}
                size={14}
                background={
                  'linear-gradient(180deg,rgba(255, 255, 255, 0.14) 0%,rgba(255, 255, 255, 0.2175) 100%)'
                }
                fontWeight={400}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
