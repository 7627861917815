import styles from './bonus.module.scss';
import cup from './1.png';

const Bonus = ({ bonus }) => {
  return (
    <div className={styles.bonus__container}>
      <div className={styles.bonus__left}>
        <img className={styles.img__bonus} src={bonus.img} alt="" />
        <div className={styles.bonus}>
          <p className={styles.bonus__p}>{bonus.title}</p>
          <p className={styles.bonus__p}>{bonus.date}</p>
        </div>
      </div>
      <div className={styles.bonus__right}>
        <div className={bonus.spent? styles.cupSpent : styles.cup}>
          <img src={cup} alt="cup" />
        </div>
        <div className={bonus.spent ? styles.bonus__pointsSpent: styles.bonus__points}>
        </div>
        <p className={bonus.spent ? styles.pointsSpent : styles.points}>{bonus.points}</p>
      </div>
    </div>
  );
};
export default Bonus;