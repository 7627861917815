import styles from './timesheetPageId.module.scss';
import store from '../../../toolkitRedux/index';
import { reduserSlice } from '../../../toolkitRedux/calendarReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cup from './1.png';

const TimesheetPageId = () => {
  const dispatch = store.dispatch;
  const { getDataId } = reduserSlice.actions;
  const navigate = useNavigate();
  const { id } = useParams();
  const { dataId } = useSelector((state) => state.calendar);
  console.log('dataId', dataId)
  const day = moment.unix(id);
  const [today, setToday] = useState(day)
  console.log('day', day)
  const startDay = today.clone().startOf('day').format('X');
  const endDay = today.clone().endOf('day').format('X');
  const prev = () => {
    setToday(prev => prev.clone().subtract(1, 'day'));
  }
  const next = () => {
    setToday(prev => prev.clone().add(1, 'day'));
  }
  console.log(dataId);
  useEffect(() => {
    dispatch(getDataId({startDay, endDay}));
  }, [dispatch, endDay, getDataId, startDay])
  
  return (
    <div className={styles.container}>
      <div className={styles.button__container} onClick={() => navigate('/timesheet')}>
        <button className={styles.button__close}>
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13L1 7L7 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <h2 className={styles.button__titile}>Детализация за смену</h2>
      </div>
      <div className={styles.container__info}>
        <div className={styles.container__monitor}>
          <button className={styles.button__prev} onClick={prev}>
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 13L0.5 7L6.5 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          <p className={styles.day__format}>{today.clone().format('DD')} {today.clone().format('MMMM')}</p>
          <button className={styles.button__next} onClick={next}>
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 13L6.5 7L0.499999 1" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        <div className={styles.info__time__container}>
          <div className={styles.info__time}>
            <p className={styles.info__time__p}>Начало смены</p>
            {Object.keys(dataId).length == 0 ? (
              <p className={styles.info__time__p}>-</p>
            ): (
              <p className={styles.info__time__p}>{dataId[0].startWork}</p>
            )}
            
          </div>
          <div className={styles.info__time}>
            <p className={styles.info__time__p}>Конец смены</p>
            {Object.keys(dataId).length == 0 ? (
              <p className={styles.info__time__p}>-</p>
            ): (
              <p className={styles.info__time__p}>{dataId[0].endWork}</p>
            )}
            
          </div>
          <div className={styles.info__time}>
            <p className={styles.info__time__p}>Рабочее время</p>
            {Object.keys(dataId).length == 0 ? (
              <p className={styles.info__time__p + " " + styles.p__blue}>-</p>
            ): (
              <p className={styles.info__time__p + " " + styles.p__blue}>{dataId[0].allTimeWork}</p>
            )}
            
          </div> 
        </div>
      </div>
      {((Object.keys(dataId).length != 0) && (dataId[0].achievement)) && (
        <div className={styles.achievements__container}>
          <div className={styles.achievements}>
            <p className={styles.achievements__p}>Получено достижение<br/> &quot;{dataId[0].achievementName}&quot;</p>
            <img src={dataId[0].achievementImg} alt="achievement" />
          </div>
          <div className={styles.achievements}>
            <p className={styles.achievements__p}>Начислено бонусных<br /> баллов</p>
            <div className={styles.bonus__right}>
              <div className={styles.cup}>
                <img src={cup} alt="cup" />
              </div>
              <div className={styles.bonus__points}>
              </div>
              <p className={styles.points}>{dataId[0].achievementPoint}</p>
            </div>
          </div>
        </div>
      )}
      <div className={styles.total__container}>
        <div className={styles.total__titile__container}>
          <p className={styles.total__h2}>Итого за смену</p>
          <p className={styles.total__h2 + " " + styles.blue}>88.57 Br</p>
        </div>
        <div className={styles.total}>
          <div className={styles.total__list}>
            <p className={styles.total__h3}>Выручка за смену</p>
            <p className={styles.total__h3}>1 716 Br</p>
          </div>
          <div className={styles.categories__container}>
            <div className={styles.categories}>
              <p className={styles.total__h4 + " " + styles.total__w1}>Позиция </p>
              <p className={styles.total__h4 + " " + styles.total__w2}>%</p>
              <p className={styles.total__h4 + " " + styles.total__w3}>Выручка</p>
              <p className={styles.total__h4 + " " + styles.total__w4}>Премия</p>
            </div>
            <div className={styles.categories}>
              <p className={styles.total__h4 + " " + styles.total__w1}>Категория А</p>
              <p className={styles.total__h4 + " " + styles.total__w2}>3</p>
              <p className={styles.total__h4 + " " + styles.total__w3}>264 Br</p>
              <p className={styles.total__h4 + " " + styles.total__w4}>7.92 Br</p>
            </div>
            <div className={styles.categories}>
              <p className={styles.total__h4 + " " + styles.total__w1}>Категория B</p>
              <p className={styles.total__h4 + " " + styles.total__w2}>2</p>
              <p className={styles.total__h4 + " " + styles.total__w3}>214 Br</p>
              <p className={styles.total__h4 + " " + styles.total__w4}>4.28 Br</p>
            </div>
            <div className={styles.categories}>
              <p className={styles.total__h4 + " " + styles.total__w1}>Прочие товары</p>
              <p className={styles.total__h4 + " " + styles.total__w2}>1</p>
              <p className={styles.total__h4 + " " + styles.total__w3}>1 238 Br</p>
              <p className={styles.total__h4 + " " + styles.total__w4}>12.38 Br</p>
            </div>
          </div>
          <div className={styles.total__list}>
            <p className={styles.total__h3}>Премия за выручку</p>
            <p className={styles.total__h3}>24.58 Br</p>
          </div>
          <div className={styles.total__list}>
            <p className={styles.total__h3}>Оклад</p>
            <p className={styles.total__h3}>45.94 Br</p>
          </div>
          <div className={styles.total__list}>
            <p className={styles.total__h3}>Премия</p>
            <p className={styles.total__h3}>75 Br</p>
          </div>
          <div className={styles.total__list}>
            <p className={styles.total__h3}>Чаевые</p>
            <p className={styles.total__h3}>18.05 Br</p>
          </div>
          <div className={styles.total__list}>
            <p className={styles.total__h3 + " " + styles.red}>Штраф</p>
            <p className={styles.total__h3 + " " + styles.red}>5.00 Br</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TimesheetPageId;