import { useNavigate } from 'react-router-dom';
import { Award } from '../../../components/Award';
import { ProgressBarCircle } from './ProgressBarCircle';
import styles from './RatingBlock.module.scss';
import { employeeData } from '../../../mockData/Data';

export const RatingBlock = () => {
  const {
    indicators,
    indicators__wrapper,
    indicators__block,
    indicators__title,
    indicators__data,
    indicators__plan,
    indicators__circle,
    indicators__circle_plan,
    indicators__value,
    indicators__fact,
    indicators__circle_fact,
    indicators__block_bonus,
    indicators__title_bonus,
    indicators__title_rating,
    indicators__rating,
    indicators__star,
    indicators__reviews,
  } = styles;

  const revenueDayPlan = employeeData.salesPlanRevenueDay.toLocaleString();
  const revenueDayFact = employeeData.salesFactRevenueDay.toLocaleString();
  const revenueMonthPlan = employeeData.salesPlanRevenueMonth.toLocaleString();
  const revenueMonthFact = employeeData.salesFactRevenueMonth.toLocaleString();
  const progressDay = (
    (employeeData.salesFactRevenueDay / employeeData.salesPlanRevenueDay) *
    100
  ).toFixed(0);
  const progressMonth = (
    (employeeData.salesFactRevenueMonth / employeeData.salesPlanRevenueMonth) *
    100
  ).toFixed(0);

  const indicatorsData = [
    {
      title: 'План продаж на сегодня',
      progress: progressDay,
      valuePlan: `Выручка ${revenueDayPlan} Br`,
      valueFact: `Выполнено ${revenueDayFact} Br`,
    },
    {
      title: 'План продаж на месяц',
      progress: progressMonth,
      valuePlan: `Выручка ${revenueMonthPlan} Br`,
      valueFact: `Выполнено ${revenueMonthFact} Br`,
    },
  ];

  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <section className={indicators}>
      <div className="container">
        <div className={indicators__wrapper}>
          {indicatorsData.map((indicator, index) => {
            return (
              <div key={index} className={indicators__block}>
                <div className={indicators__title}>{indicator.title}</div>
                <ProgressBarCircle progress={indicator.progress} />
                <div className={indicators__data}>
                  <div className={indicators__plan}>
                    <div
                      className={[
                        indicators__circle,
                        indicators__circle_plan,
                      ].join(' ')}
                    ></div>
                    <div className={indicators__value}>
                      {indicator.valuePlan}
                    </div>
                  </div>
                  <div className={indicators__fact}>
                    <div
                      className={[
                        indicators__circle,
                        indicators__circle_fact,
                      ].join(' ')}
                    ></div>
                    <div className={indicators__value}>
                      {indicator.valueFact}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className={[indicators__block, indicators__block_bonus].join(' ')}
            onClick={() => handleClick('/history')}
          >
            <div
              className={[indicators__title, indicators__title_bonus].join(' ')}
            >
              Накопленные бонусы
            </div>
            <div className={indicators__data}>
              <Award
                countAwards={employeeData.achievement_bonuses_sum}
                width={54}
                size={14}
              />
            </div>
          </div>
          <div
            className={indicators__block}
            onClick={() => handleClick('/rating')}
          >
            <div
              className={[indicators__title, indicators__title_rating].join(
                ' '
              )}
            >
              Рейтинг
            </div>
            <div className={indicators__rating}>
              <div className={indicators__star}></div>
              <div className={indicators__value}>{employeeData.rating}</div>
            </div>
            <div className={indicators__reviews}>
              {employeeData.reviews} отзывов
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
