import styles from './fine.module.scss';
import './swiper.scss';
import Violation from './violation';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css';
import { FreeMode } from 'swiper';
import { getTime } from '../../Possibilities/components/helpers';

const Fine = ({ fines }) => {
  const [details, setDetails] = useState(false);

  function getDetails() {
    if (!details) {
      setDetails(true);
    } else {
      setDetails(false);
    }
  }
  let result = 0;
  fines.fines_details.map((fine) => {
    result += Number(fine.fine_amount);
  });
  return (
    <div className={styles.fine__container}>
      <div className={styles['control-check__container']}>
        <p className={styles['control-check__title']}>Контрольная проверка</p>
        <p className={styles['control-check__date']}>
          {getTime(fines.fine_date)}
        </p>
      </div>
      <div className={styles.violations__container}>
        <div className={styles.violations__header}>
          <p className={styles.violations__title + ' ' + styles.header}>
            Нарушение
          </p>
          <p className={styles.violations__sum + ' ' + styles.header}>
            Удержано (br)
          </p>
        </div>
        <div className={styles.violations__list__container}>
          {fines.fines_details.map((fine, id) => (
            <Violation fine={fine} key={id} />
          ))}
        </div>
        <div className={styles['violations__final-sum']}>
          <p className={styles.violations__title}>Итого:</p>
          <p className={styles.violations__sum + ' ' + styles.final}>
            {result}
          </p>
        </div>
        <button className={styles['fine__button-details']} onClick={getDetails}>
          Подробнее
          {details ? (
            <svg
              className={styles['fine__button-svg']}
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 7L7 1L13 7"
                stroke="#EBEBEB"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              className={styles['fine__button-svg']}
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="#EBEBEB"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </div>
      {details && (
        <div className={styles.details__container}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            freeMode={true}
            modules={[FreeMode]}
            className="mySwiper"
          >
            {fines.fines_photo.map((img, id) => (
              <SwiperSlide key={id}>
                <img src={img} alt="img" className="fine-img" />
              </SwiperSlide>
            ))}
          </Swiper>
          <p className={styles.checking}>Проверяющий: {fines.checking}</p>
        </div>
      )}
    </div>
  );
};
export default Fine;
