import styles from './Achievements.module.scss';
import Ideological from './assets/ideological.svg';
import IdeologicalActive from './assets/ideologicalColor.svg';
import Skills from './assets/skill.svg';
import SkillsActive from './assets/skillColor.svg';
import Barista from './assets/barista.svg';
import BaristaActive from './assets/baristaColor.svg';
import Burning from './assets/burning.svg';
import BurningActive from './assets/burningColor.svg';
import WeekEmployee from './assets/cup.svg';
import WeekEmployeeActive from './assets/cupColor.svg';
import Baker from './assets/bakery.svg';
import BakerActive from './assets/bakeryColor.svg';
import Sale from './assets/sale.svg';
import SaleActive from './assets/saleColor.svg';
import Charming from './assets/charming.svg';
import CharmingActive from './assets/charmingColor.svg';
import Stacker from './assets/stacker.svg';
import StackerActive from './assets/stackerColor.svg';
import TeemPlayer from './assets/teemplayer.svg';
import TeemPlayerActive from './assets/teemplayerColor.svg';
import { ProgressBarSvg } from './components/ProgressBarSvg';
import { useState } from 'react';
import { Modal } from '../../components/Modal/Modal.jsx';
import { ModalContent } from './components/ModalContent';
import { ProgressBarLine } from '../../components/ProgressBarLine/ProgressBarLine';
import { AchieveImageBig } from '../../components/AchieveImage/big';

export const achievementsData = [
  {
    id: 1,
    title: 'Жгучий бариста',
    img: Burning,
    imgActive: BurningActive,
    count: 0,
    percentage: 73,
    period: 'week',
    award: 25,
    content:
      'Увеличить продажи кофе «Pepperchino» на 40% по сравнению со средненедельными продажами по сети',
    coinsText: 'Сумма, которой не хватает до средненедельных продаж: 125,08 Br',
  },
  {
    id: 2,
    title: 'Бариста-виртуоз',
    img: Barista,
    imgActive: BaristaActive,
    count: 4,
    percentage: 73,
    period: 'week',
    award: 15,
    rating: 5,
    content: `Обеспечить максимальную выручку по сети от продажи напитков за неделю`,
    coinsText: 'Сумма, которой не хватает до лидера 100,56 Br',
  },
  {
    id: 3,
    title: 'Сотрудник недели',
    img: WeekEmployee,
    imgActive: WeekEmployeeActive,
    count: 1,
    percentage: 73,
    period: 'week',
    award: 25,
    rating: 4,
    content: `Обеспечить 
    максимальную выручку 
    по сети за неделю
    
    Отсутствие штрафов 
    и отзывов с рейтингом 
    4 и ниже за неделю`,
    coinsText: 'Сумма, которой не хватает до лидера 200,46 Br',
  },
  {
    id: 4,
    title: 'Мастер-пекарь',
    img: Baker,
    imgActive: BakerActive,
    count: 0,
    percentage: 53,
    period: 'week',
    award: 15,
    rating: 1,
    content: `Обеспечить максимальную выручку по сети от продажи выпечки за неделю`,
    coinsText: 'Сумма, которой не хватает до лидера 0 Br',
  },
  {
    id: 5,
    title: 'Продаван',
    img: Sale,
    imgActive: SaleActive,
    count: 0,
    percentage: 73,
    period: 'week',
    award: 15,
    content: `В течении недели продать все позиции 
    из меню заведения`,
    muffinText: 'Вам осталось продать 8 позиций',
  },
  {
    id: 6,
    title: 'Обаяшка',
    img: Charming,
    imgActive: CharmingActive,
    count: 0,
    percentage: 85,
    period: 'week',
    award: 5,
    rating: 4,
    content: `Получить максимальное количество чаевых 
    по сети за неделю`,
    coinsText: 'Сумма, которой не хватает до лидера 10 Br',
  },
  {
    id: 7,
    title: 'Идейный',
    img: Ideological,
    imgActive: IdeologicalActive,
    count: 3,
    percentage: 100,
    period: 'once',
    award: 15,
    content: `Написать предложение по улучшению работы заведения.`,
    getThisWeek: true,
  },
  {
    id: 8,
    title: 'На скиле',
    img: Skills,
    imgActive: SkillsActive,
    count: 0,
    percentage: 0,
    period: 'once',
    award: 15,
    content: `Пройти один из образовательных курсов помимо базового обучения работе бариста, техники безопасности, пожарной безопасности 
    и т.д.`,
    getThisWeek: false,
  },
  {
    id: 9,
    title: 'Товароукладчик',
    img: Stacker,
    imgActive: StackerActive,
    count: 0,
    percentage: 0,
    period: 'once',
    award: 15,
    content: `Обеспечить идеальную выкладку товара. 
    Присваивается по результатам проверки.`,
    getThisWeek: false,
  },
  {
    id: 10,
    title: 'В команде',
    img: TeemPlayer,
    imgActive: TeemPlayerActive,
    count: 1,
    percentage: 100,
    period: 'once',
    award: 15,
    content: `Успешно пройти испытательный срок`,
    getThisWeek: false,
  },
];

export const AchievementsPage = () => {
  const {
    achievements,
    achievements__title,
    achievements__list,
    achievements__item,
    achievements__img,
    achievements__count,
    achievements__count_show,
    achievements__count_hide,
    achievements__name,
    achievements__progress,
  } = styles;
  const [modalActive, setModalActive] = useState(false);
  const [openId, setOpenId] = useState(null);
  const openPopUp = (event) => {
    const id = event.currentTarget.dataset.id;
    const data = achievementsData.find((item) => item.id === +id);
    setOpenId(data);
    setModalActive(true);
    document.body.classList.add('block');
    document.body.style.touchAction = 'none';
  };

  const closePopUp = () => {
    setModalActive(false);
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  };

  return (
    <>
      <main className="main">
        <section className={achievements}>
          <div className="container">
            <div className={[achievements__title, 'title'].join(' ')}>
              Достижения
            </div>
            <div className={achievements__list}>
              {achievementsData.map((ach) => {
                return (
                  <div
                    key={ach.id}
                    className={achievements__item}
                    data-id={ach.id}
                    onClick={(event) => openPopUp(event)}
                  >
                    <div className={achievements__img}>
                      <AchieveImageBig
                        progress={ach.percentage}
                        imgActive={ach.imgActive}
                        imgDisable={ach.img}
                      />
                      {ach.percentage &&
                      ach.percentage > 0 &&
                      ach.percentage < 100 ? (
                        <ProgressBarSvg progress={ach.percentage} />
                      ) : null}
                      <div
                        className={
                          ach.count > 0
                            ? [
                                achievements__count,
                                achievements__count_show,
                              ].join(' ')
                            : [
                                achievements__count,
                                achievements__count_hide,
                              ].join(' ')
                        }
                      >
                        x {ach.count}
                      </div>
                    </div>
                    <div className={achievements__name}>{ach.title}</div>
                    <div className={achievements__progress}>
                      {ach.percentage > 0 && ach.period !== 'once' ? (
                        <ProgressBarLine
                          progress={ach.percentage}
                          marginTop={18}
                          marginBottom={0}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
      <Modal activeModal={modalActive} setActive={setModalActive}>
        {modalActive && <ModalContent data={openId} setActive={closePopUp} />}
      </Modal>
    </>
  );
};
