import { useEffect, useState } from 'react';
import store from '../../toolkitRedux';
import styles from './notifications.module.scss';
import { fetchData } from "../../toolkitRedux/notificationsReducer";
import Stars from '../rating/components/stars';
import { useNavigate } from 'react-router-dom';


const List = ({el}) => {
  const dispatch = store.dispatch;
  const navigate = useNavigate();
  const [name, setName] = useState(el.name);
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch])
  useEffect(() => {
    setName(el.name)
  }, [el.name])
  useEffect(() => {
    switch (name) {
      case 'rating':
        setName('Рейтинг');
        break;
      case 'fine':
        setName('Штрафы');
        break;
      case 'achievements':
        setName('Достижения');
        break;
      case 'possibilities':
        setName('Возможности');
        break;
      case 'informational':
        setName('Информационное уведомление');
        break;
      case 'service':
        setName('Сервисное уведомление');
        break;
      default:
    }
  }, [name])
  return (
    <div className={el.viewed ? styles.container__list + " " + styles.viewed : styles.container__list} key={el.id}>
      <div className={styles.date__info}>
        <p className={styles.date__p}>{el.date}</p>
        <span className={styles.date__p}>/</span>
        <p className={styles.name__p}>{name}</p>
      </div>
      {el.name === "rating" && (
        <div className={styles.details}>
          <div className={styles.details__container}>
            <p>{el.details.date}</p>
            <p>Оценка</p>
            <Stars number={el.details.Grade} />
          </div>
          <button className={styles.button} onClick={() => navigate('/rating')}>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L7 7L0.999999 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        
      )}
      {(el.name === "rating" || el.name === "achievements" || el.name === "possibilities") && (
        <p className={styles.text_rating}>{el.text}</p>
      )}
      {(el.name === "informational" || el.name === "service") && (
        <p className={styles.text}>{el.text}</p>
      )}
      {el.name === "fine" && (
        <div className={styles.fine__container}>
          <p className={styles.text_rating}>{el.text}</p>
          <button className={styles.button} onClick={() => navigate('/fines')}>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L7 7L0.999999 1" stroke="#EBEBEB" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};
export default List;