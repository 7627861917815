import styles from'./calendar.module.scss';
import DayItem from './dayItem';

const Calendar = ({ calendar, today }) => {
  const daysWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  return (
    <>
      <div className={styles.days__week}>
        {daysWeek.map((day) => (
          <p className={styles.days__title} key={day}>{day}</p>
        ))}
      </div>
      <div className={styles.calendar__container}>
      {
          calendar.map((dayItem) => (
            <DayItem dayItem={dayItem} key={dayItem.format('DDMMYYYY')} today={today} />
        ))
      }
    </div>
    </>
    
  );
};
export default Calendar;