import styles from './Award.module.scss';
import coin from '../../assets/coins.svg';

export const Award = ({
  countAwards,
  width,
  size,
  background,
  fontWeight,
  imgSize = 24,
  imgSrc = `url(${coin})`,
}) => {
  const { award, award__img } = styles;

  return (
    <div
      className={award}
      style={{
        width: width,
        fontSize: size,
        background: background,
        fontWeight: fontWeight,
      }}
    >
      <div
        style={{
          width: imgSize,
          height: imgSize,
          backgroundImage: imgSrc,
        }}
        className={award__img}
      ></div>
      <p>{countAwards}</p>
    </div>
  );
};
