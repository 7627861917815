import styles from './homepage.module.scss';
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.homepage__container}>
      <img src={logo} alt="logo" />
      <h1 className={styles.title}>FCM</h1>
      <div className={styles.button__container}>
        <button className={styles.button} onClick={() => navigate('/login')}>Кабинет сотрудника</button>
        <button className={styles.button + " " + styles.none}>Кабинет заведения</button>
        <button className={styles.button + " " + styles.none}>Кабинет сети</button>
      </div>
    </div>
  );
};
export default Homepage;