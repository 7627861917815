import { useEffect } from 'react';
import { useState } from 'react';
import { useToggle } from '../../../hooks/Hooks';
import { employeeData } from '../../../mockData/Data';
import styles from './Select.module.scss';

export const SelectPossibility = ({
  options,
  checkedOption,
  onChangeOption,
  countAwards,
}) => {
  const {
    select,
    select__value,
    select__list,
    select__list_hide,
    select__option,
    select__option_disable,
  } = styles;

  const [isVisible, setIsVisible] = useToggle();
  const [isDisable, setIsDisable] = useState([false, false]);
  const [text, setText] = useState(['', '']);

  const handleChoseOption = (event) => {
    if (event.target.dataset.value === 'Бонусными баллами' && isDisable[0]) {
      return;
    }
    onChangeOption(event);
    setIsVisible();
  };

  useEffect(() => {
    const difference = countAwards - employeeData.achievement_bonuses_sum;
    if (difference > 0) {
      setIsDisable([true, false]);
      setText([
        `   (${employeeData.achievement_bonuses_sum} бонусов из ${countAwards})`,
        '',
      ]);
    } else {
      setIsDisable([false, false]);
      setText(['', '']);
    }
  }, [countAwards]);

  return (
    <div className={select}>
      <div className={select__value} onClick={setIsVisible}>
        <p>{checkedOption}</p>
        <div
          className={isVisible ? 'arrow arrow_down' : 'arrow arrow_up'}
        ></div>
      </div>
      <ul
        className={
          isVisible ? select__list : [select__list, select__list_hide].join(' ')
        }
      >
        {options.map((option, ind) => {
          return (
            <li
              key={option}
              data-value={option}
              className={
                isDisable[ind]
                  ? [select__option, select__option_disable].join(' ')
                  : select__option
              }
              onClick={(event) => handleChoseOption(event)}
            >
              {option} {text[ind]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
