import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const initialState = {
  possibilities: [],
};

export const fetchPossibilityData = createAsyncThunk(
  'possibility/fetchPossibilityData',
  async () => {
    const res = await fetch('https://dev.fcm.by/hr/api-possibility/');
    const data = await res.json();

    return data;
  }
);

export const possibilitySlice = createSlice({
  name: 'possibility',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPossibilityData.fulfilled, (state, action) => {
      state.possibilities = action.payload;
    });
  },
});

export default possibilitySlice.reducer;
