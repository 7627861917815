import { useState } from 'react';
import { AchieveImageSmall } from '../../../components/AchieveImage/small';
import { Modal } from '../../../components/Modal/Modal';
import { ProgressBarLine } from '../../../components/ProgressBarLine/ProgressBarLine';
import { ModalContentSlide } from './ModalContentSlide';
import { ProgressBarSvgMain } from './ProgressBarSvgMain';
import styles from './Slides.module.scss';

export const Slides = ({ slides }) => {
  const {
    slider,
    slider__arrow,
    slider__arrow_left,
    slider__block,
    slider__count,
    slider__count_show,
    slider__count_hide,
    slider__arrow_right,
    slider__description,
    slider__progress,
    slider__bar,
  } = styles;

  const slidesOnce = slides.filter(
    (slide) => slide.period === 'once' && slide.getThisWeek
  );
  const slidesWeek = slides.filter((slide) => slide.period !== 'once');
  const slidesData = [...slidesWeek, ...slidesOnce];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = 0;
    const newIndex =
      currentIndex === isFirstSlide ? slidesData.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = slidesData.length - 1;
    const newIndex = currentIndex === isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const isVisibleAwards = slidesData[currentIndex].count > 0 ? true : false;

  const [modalActive, setModalActive] = useState(false);
  const [openId, setOpenId] = useState(null);

  const openPopUp = (event) => {
    const id = event.currentTarget.dataset.id;
    const data = slidesData.find((item) => item.id === +id);
    setOpenId(data);
    setModalActive(true);
    document.body.classList.add('block');
    document.body.style.touchAction = 'none';
  };

  const closePopUp = () => {
    setModalActive(false);
    document.body.classList.remove('block');
    document.body.style.touchAction = 'auto';
  };

  return (
    <>
      <div className={slider}>
        <div
          className={[slider__arrow, slider__arrow_left].join(' ')}
          onClick={goToPrevious}
        ></div>
        <div
          className={slider__block}
          onClick={(event) => openPopUp(event)}
          data-id={slidesData[currentIndex].id}
        >
          <AchieveImageSmall
            progress={slidesData[currentIndex].percentage}
            imgActive={slidesData[currentIndex].imgActive}
            imgDisable={slidesData[currentIndex].img}
          />
          <div
            className={
              isVisibleAwards
                ? [slider__count, slider__count_show].join(' ')
                : [slider__count, slider__count_hide].join(' ')
            }
          >
            x {slidesData[currentIndex].count}
          </div>
          {slidesData[currentIndex].percentage &&
          slidesData[currentIndex].percentage > 0 &&
          slidesData[currentIndex].percentage < 100 ? (
            <ProgressBarSvgMain
              progress={slidesData[currentIndex].percentage}
            />
          ) : null}
        </div>
        <div
          className={[slider__arrow, slider__arrow_right].join(' ')}
          onClick={goToNext}
        ></div>
      </div>
      <h4 className={slider__description}>{slidesData[currentIndex].title}</h4>

      <div className={slider__progress}>
        <div className={slider__bar}>
          {slidesData[currentIndex].period !== 'once' ? (
            <ProgressBarLine
              progress={slidesData[currentIndex].percentage}
              marginBottom={30}
              marginTop={41}
            />
          ) : (
            <div style={{ height: '78px' }}></div>
          )}
        </div>
      </div>
      <Modal activeModal={modalActive} setActive={setModalActive}>
        {modalActive && (
          <ModalContentSlide data={openId} setActive={closePopUp} />
        )}
      </Modal>
    </>
  );
};
