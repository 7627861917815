import styles from './Products.module.scss';

import { VscClose } from 'react-icons/vsc';

import Croissants from './assets/croissants.svg';
import Donuts from './assets/donuts.svg';
import BerryCake from './assets/berrycake.svg';
import Cake from './assets/cake.svg';
import Cheesecake from './assets/cheesecake.svg';
import IceCream from './assets/cream.svg';
import Drinks from './assets/coffee.svg';
import { useState } from 'react';
import { sortProductsBySale } from '../../utils/products';
import { useNavigate } from 'react-router';

const productsData = [
  {
    id: 11,
    name: 'Круассаны',
    price: '3.90',
    imageSrc: Croissants,
    group: 'deserts',
    sale: false,
  },
  {
    id: 22,
    name: 'Донаты',
    price: '1.90',
    imageSrc: Donuts,
    group: 'deserts',
    sale: true,
  },
  {
    id: 33,
    name: 'Пирог с вишней и голубикой',
    price: '3.50',
    imageSrc: BerryCake,
    group: 'deserts',
    sale: false,
  },
  {
    id: 44,
    name: 'Чизкейк творожный с лимоном',
    price: '5.90',
    imageSrc: Cake,
    group: 'deserts',
    sale: false,
  },
  {
    id: 55,
    name: 'Пирог с вишней и малиной',
    price: '5.90',
    imageSrc: BerryCake,
    group: 'deserts',
    sale: true,
  },
  {
    id: 66,
    name: 'Сырники',
    price: '4.30',
    imageSrc: Cheesecake,
    group: 'cheesecake',
    sale: true,
  },
  {
    id: 77,
    name: 'Круассаны',
    price: '3.90',
    imageSrc: Croissants,
    group: 'bakehouse',
    sale: false,
  },
  {
    id: 88,
    name: 'Мороженое',
    price: '2.90',
    imageSrc: IceCream,
    group: 'iceCream',
    sale: true,
  },
  {
    id: 99,
    name: 'Кофе',
    price: '3.50',
    imageSrc: Drinks,
    group: 'drinks',
    sale: false,
  },
];

export const ProductsPage = () => {
  const productsGroups = [
    {
      id: 1,
      title: 'Десерты',
      imageSrc: Cake,
      group: 'deserts',
      products: sortProductsBySale([
        ...productsData.filter((item) => item.group === 'deserts'),
      ]),
    },
    {
      id: 2,
      title: 'Наша пекарня',
      imageSrc: Croissants,
      group: 'bakehouse',
      products: sortProductsBySale([
        ...productsData.filter((item) => item.group === 'bakehouse'),
      ]),
    },
    {
      id: 3,
      title: 'Сырники',
      imageSrc: Cheesecake,
      group: 'cheesecake',
      products: sortProductsBySale([
        ...productsData.filter((item) => item.group === 'cheesecake'),
      ]),
    },
    {
      id: 4,
      title: 'Мороженое',
      imageSrc: IceCream,
      group: 'iceCream',
      products: sortProductsBySale([
        ...productsData.filter((item) => item.group === 'iceCream'),
      ]),
    },
    {
      id: 5,
      title: 'Кофе',
      imageSrc: Drinks,
      group: 'drinks',
      products: sortProductsBySale([
        ...productsData.filter((item) => item.group === 'drinks'),
      ]),
    },
  ];
  const {
    products,
    products__list,
    products__item,
    products__arrow,
    products__arrow_down,
    products__show,
    products__hide,
    products__items,
    products__items_exist,
    products__items_sale,
    products__price,
    products__name,
    products__disable,
    products__line,
    products__exit,
  } = styles;

  const [isOpenProductGroup, setIsOpenProductGroup] = useState({
    deserts: false,
    bakehouse: false,
    cheesecake: false,
    iceCream: false,
    drinks: false,
  });

  const changeOpenProductGroup = (group) => {
    setIsOpenProductGroup({
      ...isOpenProductGroup,
      [`${group}`]: !isOpenProductGroup[`${group}`],
    });
  };

  const navigate = useNavigate();

  return (
    <main className="main">
      <div className="container">
        <div className={products__exit}>
          <VscClose size={20} onClick={() => navigate(-1)} />
        </div>
        <div className={products}>
          <ul className={products__list}>
            {productsGroups.map((product) => (
              <div key={product.id}>
                <li
                  className={products__item}
                  onClick={() => changeOpenProductGroup(product.group)}
                >
                  <img src={product.imageSrc} alt="Product image" />
                  <p className="name">{product.title}</p>
                  <div
                    className={
                      isOpenProductGroup[product.group]
                        ? [products__arrow, products__arrow_down].join(' ')
                        : products__arrow
                    }
                  ></div>
                </li>
                <ul
                  className={
                    isOpenProductGroup[product.group]
                      ? products__show
                      : products__hide
                  }
                  key={product.group + product.id}
                >
                  {product.products.map((item) => (
                    <>
                      <li
                        key={item.id}
                        className={
                          item.sale
                            ? [products__items, products__items_sale].join(' ')
                            : [products__items, products__items_exist].join(' ')
                        }
                      >
                        <img src={item.imageSrc} alt="Product image" />
                        <p className={products__name}>{item.name}</p>
                        <div className={products__price}>{item.price} Br</div>
                        {item.sale ? (
                          <div className={products__disable}>
                            <div className={products__line}></div>
                          </div>
                        ) : null}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};
