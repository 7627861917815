import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import data from './dataFines.json';
export const initialState = {
  data: {},
  details: false,
};

export const fetchData = createAsyncThunk('user/fetchData', async (userId) => {
  const res = await fetch(`https://dev.fcm.by/fine-list/${userId}`);
  const data = await res.json();

  return data;
});

// export const fetchData = createAsyncThunk('user/fetchData', async () => {
//   return data;
// });

export const reduserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addDetails(state) {
      state.details = true;
    },
    removeDetails(state) {
      state.details = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});
export default reduserSlice.reducer;
