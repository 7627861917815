import { useState } from 'react';
import { employeeData, PROFILE_INFO } from '../../mockData/Data';
import { PROFILE_VALUES } from './constants';
import { VscClose } from 'react-icons/vsc';

import pen from './assets/pen.svg';
import styles from './profile.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import './components/swiperProfile.scss';
import 'swiper/less/navigation';
import 'swiper/less/pagination';
import { Bands } from './components/Bands';
import { Award } from '../../components/Award';

export const Profile = () => {
  const {
    title__wrapper,
    close__icon,
    swiper__wrapper,
    wrapper,
    load,
    check,
    trash,
  } = styles;
  const [isPhotoChoose, setIsPhotoChoose] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);

  const handleOpenPhotoGallery = () => {
    setIsPhotoChoose(true);
  };

  const changeActiveSlider = (number) => {
    setSlideNumber(number);
  };

  function setImage(image) {
    const imageFile = URL.createObjectURL(image);
    const result = { type: image.type, url: imageFile };
    if (image.size > 200000000) {
      console.log('Size is big');
    } else {
      PROFILE_INFO.userPhoto.unshift(result.url);
    }
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
      setSlideNumber(0);
      setIsPhotoChoose(false);
    }
  };

  const closePhotoGallery = () => {
    const choosePhoto = PROFILE_INFO.userPhoto.splice(slideNumber, 1);
    PROFILE_INFO.userPhoto.unshift(choosePhoto[0]);
    setSlideNumber(0);
    setIsPhotoChoose(false);
  };

  const deletePhoto = () => {
    if (PROFILE_INFO.userPhoto.length === 1) {
      return;
    }
    PROFILE_INFO.userPhoto.splice(slideNumber, 1);
    setIsPhotoChoose(false);
    setSlideNumber(0);
  };

  return (
    <section className={styles.profile}>
      <div className={title__wrapper}>
        <h3 className={styles.profileTitle}>Профиль пользователя</h3>
        {isPhotoChoose && (
          <VscClose
            className={close__icon}
            size={20}
            onClick={() => setIsPhotoChoose(false)}
          />
        )}
      </div>
      {!isPhotoChoose && (
        <div className={styles.form__list}>
          <img
            src={PROFILE_INFO.userPhoto[0]}
            alt="user"
            className={styles.profilePhotos}
            onClick={handleOpenPhotoGallery}
          />
          <img
            src={pen}
            alt="pen"
            className={styles.pen}
            onClick={handleOpenPhotoGallery}
          />
        </div>
      )}
      {isPhotoChoose && (
        <div className={swiper__wrapper}>
          <Swiper
            modules={[Pagination]}
            slidesPerView={'auto'}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            className="profileSwiper"
            onSlideChange={(event) => changeActiveSlider(event.activeIndex)}
          >
            {PROFILE_INFO.userPhoto.map((photo, ind) => (
              <SwiperSlide key={ind}>
                <img src={photo} alt="img" className="avatar" />
              </SwiperSlide>
            ))}
            <Bands
              slidesCount={PROFILE_INFO.userPhoto.length}
              slideNumber={slideNumber}
            />
          </Swiper>
          <div className={wrapper}>
            <label className={load}>
              <input
                type="file"
                accept="image/*;capture=camera"
                className={styles.profilePhoto}
                onChange={handleFileChange}
              />
            </label>
            <div onClick={closePhotoGallery} className={check} />
            <div onClick={deletePhoto} className={trash} />
          </div>
        </div>
      )}
      <div className={styles.profileWrapper}>
        <div>
          {PROFILE_INFO.userPhoto.map((photo, index) => (
            <img key={index} src={photo.url} />
          ))}
        </div>
        <div className={styles.position}>
          <p>{PROFILE_INFO.position}</p>
          <Award
            countAwards={employeeData.achievement_bonuses_sum}
            width={50}
            size={12}
            background={'#202021'}
          />
        </div>
        {PROFILE_VALUES.map(({ title, value }) => (
          <div key={title}>
            <h5>{title}</h5>
            <input type="text" defaultValue={value} readOnly />
          </div>
        ))}
      </div>
    </section>
  );
};
