import styles from './fines.module.scss';
import Fine from './components/fine';
import { useEffect } from 'react';
import store from '../../toolkitRedux/index';
import { fetchData } from '../../toolkitRedux/toolkitReducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Fines = () => {
  const dispatch = store.dispatch;
  const data = useSelector((state) => state.fine.data);
  const userId = useSelector((state) => state.auth.userId);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  useEffect(() => {
    dispatch(fetchData(userId));
  }, [dispatch, userId]);
  if (Object.keys(data).length == 0) {
    return (
      <main className="main">
        <h2>Ничего не найдено </h2>
      </main>
    );
  }
  return (
    <main className="main">
      <button className={styles.button__back} onClick={goBack}>
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 13L1 7L7 1"
            stroke="#EBEBEB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Штрафы и удержания
      </button>
      <div className="list__fines">
        {data.map((fines) => (
          <Fine fines={fines} key={fines.id} />
        ))}
      </div>
    </main>
  );
};
export default Fines;
