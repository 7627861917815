import styles from './history.module.scss';
import bonus from './bonus.png'
import HistotyContainer from './component/histotyContainer';
import { useSelector } from 'react-redux'
import store from '../../toolkitRedux/index';
import { useEffect } from "react";
import { fetchData } from "../../toolkitRedux/toolkitReducerHistory";

const History = () => {
  const { data, points } = useSelector((state) => state.history);
  const dispatch = store.dispatch;
  useEffect(() => {
      dispatch(fetchData());
    
  }, [dispatch]);
  if (Object.keys(data).length == 0) {
    return (
      <div>
        <h2 >Ничего не найдено </h2>
      </div>
    );
  }
  return (
    <div className={styles.history__container}>
      <div className={styles.title__container}>
        <h1 className={styles.title__h1}>История использования баллов</h1>
        <div className={styles.bonuses__container}>
          <img src={bonus} alt="bonus" />
          <div className={styles.bonuses__info}>
            <p className={styles.info__p}>У вас накопленно</p>
            <p className={styles.info__points}>{points}</p>
            <p className={styles.info__p}>бонусных баллов</p>
          </div>
        </div>
      </div>
      <HistotyContainer />
    </div>
  );
};
export default History;